import { Form } from "antd";
import { Header, SideMenu } from "../../../Components";
import * as Styles from "./CustomizedReportStyles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GetAllProducts } from "../../../slices/Product/Helpers/GetAllProducts";
import { useState, useEffect } from "react";
import { importEngine } from "../../../API/ImportEngine";
import moment from "moment";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import { Uniview } from "../../../API/Uniview";

function CustomizedReport() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { allProduct } = useAppSelector((store) => store.product);
  const [submitting, setSubmitting] = useState(false);

  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [activeTabValue, setActiveTabValue] = useState<string>("1");
  //const [submitting, setSubmitting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [searchedRecordData, setSearchedRecordData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
  const [emailRecordsDrawerVisibility, setEmailRecordsDrawerVisibility] =
    useState<boolean>(false);
  const [isWorkflowFieldHasValue, setIsWorkflowFieldHasValue] =
    useState<boolean>(true);
  const [generatingBtnLoading, setGeneratingBtnLoading] =
    useState<boolean>(false);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(0);

  

  const onFinish = async (values: any) => {
    setSubmitting(true);
   
   
    const selected = [
      "id",
      "articleTitle",
      "abstract",
      "authors",
      "source",
      "caseTranslated",
      "categorization",
      "volumeNumber",
      'externalRefID',
      "duplicate",
      "duplicateOf",     
      "duplicateComment",
      "stage",
      "issueNumber",
      "page",
      "publicationDate",
      "fullTextLink",
      "searchStrategy",
      "citation",
      "dateOfSearch",
      "subCategorization",
      "comment",
      "comments",
      "meta",
      "ptData",
      "D_2_3_patientAgeGroupAsPerReporter",
      "D_5_sex",
      "D_2_2a_ageAtTimeOfOnsetOfReactionEventNumber",
      "D_2_2b_ageAtTimeOfOnsetOfReactionEventUnit",
      "reportData",
      "E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary",
      "IME",
      "DME",
      "seriousnessValue",
      "productRelatedData",
      "isMLM",
      'G_k_4_r_dosageAndRelevantInformationRepeatAsNecessary',
      'G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary1',
      'G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary',
      "originCountry",
      "ReporterName",
      "translationRequestStatus",
      "ftpRequestStatus",
      "socData",
      "completionStatus",
      "workflow1DD",
      "workflow1CD",
      "workflow1CS",
      "workflow2DD",
      "workflow2CD",
      "workflow2CS",
      "qcDD",
      "qcCD",
      "qcCS"
      
    ];
   
    const payload = {
     
    //  productName: [values.productName],
      startDate: moment(values.dateRange[0]._d).format("YYYY-MM-DD"),
      endDate: moment(values.dateRange[1]._d).format("YYYY-MM-DD"),
      viewName: "all",
      selectedColumn: selected,
    };
    values = {
      
      ">": payload.startDate,
      "<": payload.endDate,
    };
    const univiewService = new Uniview("import-engine", "all");
   
    const res =  await univiewService.generateCSV(    
      '',      
      '',  
      {date_of_search:values},      
      '',
      '',   
       
      selected,
      
    );
    // const res = await importEngine.generateAssesmentReport(
    //   payload,
    //   "Customized Report"
    // );
    if (res) {
      ReNotification({
              header: "Downloaded Successfully.",
              description: "File Download Successful! ",
              type: "success",
            });
    } else {
     ReNotification({
             header: "File Download Failed",
             description: "Please Try Again Later.",
             type: "error",
           });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(GetAllProducts());
  }, []);

  return (
    <Styles.Container className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <div className="form-wrapper">
            <ReusableForm
              formInstance={form}
              onSubmit={onFinish}
              submitBtnText="Generate"
              submitBtn
              resetBtn
            >
              <div className="filter">
               
                <ReDatePicker
                  label="Day 0"
                  name="dateRange"
                  required
                  type="rangePicker"
                  dateFormat="DD-MMM-YYYY"
                  featureDates
                  width="100%"
                />
              </div>
            </ReusableForm>
          </div>
        </Styles.SubContainer>
      </div>
    </Styles.Container>
  );
}

export default CustomizedReport;
