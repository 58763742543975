import { Collapse } from "antd";
import * as styles from "./FAQStyles";

const faqData = [
    {
        key: '1',
        heading: "What is UNITYai.Lit?",
        content: `UNITYai.Lit is an advanced literature management tool developed by Soterius, Inc. It is designed to streamline and automate the process of literature monitoring and management.`,
    },
    {
        key: '2',
        heading: "What does UNITYai.Lit do?",
        content: `UNITYai.Lit streamlines literature management by adopting a structured approach to monitoring. This tool enables a comprehensive 'Once & Done' review of both abstracts and full-text articles, significantly reducing the time spent on review and data aggregation.`,
    },
    {
        key: '3',
        heading: "Can UNITYai.Lit generate reports?",
        content: `Privileged users can generate the report from the Report menu after applying the required filters. (Please refer to Section I of UNITYai.Lit User Manual)`,
    },
    {
        key: '4',
        heading: "How are Individual Case Safety Reports (ICSRs) processed in UNITYai.Lit?",
        content: `UNITYai.Lit processes and transfers near-ready ICSRs in E2B format into safety databases, ensuring that all necessary safety information is accurately captured and reported.`,
    },
    {
        key: '5',
        heading: "How the system sends alerts based on categorization",
        content: `The system sends an email notification to the email ID configured for the purpose in the Sponsor’s Configuration.`,
    },
    {
        key: '6',
        heading: "What if I do not receive the Secure Access Code in my inbox",
        content: `Please check your junk/spam folder to see if you have received the email there. 
Please also add unityai.lit@soterius.com to your safe sender’s list so that emails are directed to your inbox.
`,
    },
    {
        key: '7',
        heading: `How are the records moved to QC?`,
        content: `In UNITYai.Lit, the System Administrator can set up the QC scheduler for each Workspace. They can choose a specific day of the week or month for the QC scheduler to run. The scheduler will automatically process records based on the Quality Check Frequency and Configuration. However, records are only moved to the QC workflow once all records in the imported file are moved to the processed workflow.
`,
    },
    {
        key: '8',
        heading: "Where can user find the detailed report of records processed by the QC Scheduler",
        content: `The detailed report can be found under the QC Scheduler logs sub-tab in the Audit Trails section of UNITYai.Lit.`,
    },
    {
        key: '9',
        heading: `How the literatures are assigned in the system.`,
        content: `In UNITYai.Lit, Users with manager role can assign literature records to other users through the Work Allocation screen under the Literature Records menu. They can do this either manually or automatically, based on the users’ access to different workflows.`,
    },
    {
        key: '10',
        heading: `How does UNITYai.Lit handle non-English literature records?`,
        content: `UNITYai.Lit automatically identifies literature records that are in non-English languages at the time of import. Using an auto-translation engine, these records are translated into English. The translated records are then displayed on the All Records screen with the column “Auto Translated” marked as “Yes.” `,
    },
    {
        key: '11',
        heading: "How can user export literature records as XML files in UNITYai.Lit?",
        content: `In UNITYai.Lit, User can export literature records as XML files directly from the All Records screen. There is an Export as XML icon located on the left side of the Record ID for each record. By clicking on this icon, an XML file will be generated for the selected record.`,
    },
    {
        key: '12',
        heading: "How is data security ensured on UNITYai.Lit",
        content: `To access the information on UNITYai.Lit, users need a Secure Access Code (SAC), which is received on their email ID. Hence unauthorized person can not access any information from the system.`,
    },
    {
        key: '13',
        heading: "What if I have queries on how to use UNITYai.Lit or face any technical issues?",
        content: `Visit the Support page to access user manuals through the Knowledge Repository link. Alternatively, raise a support ticket, and we will respond promptly.`,
    }
];

function FAQ() {
    const { Panel } = Collapse;

    return (
        <styles.Container className="manual-container">
            <Collapse defaultActiveKey={['1']}>
                {faqData?.map((faq)=>(<Panel header={faq.heading} key={faq.key}>
                    {faq.content}
                </Panel>))}
            </Collapse>
        </styles.Container>
    )
}

export default FAQ