import * as Styles from "./QcConfigurationDrawerStyles";
import { useEffect, useState } from "react";
import { tenant } from "../../../../API/UserManagement/Tenant";
import { DeleteOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import {
  capitalizeSubstring,
  capitalizeWordsExceptExceptions,
  convertToCamelCase,
  convertToSimpleCase,
} from "../../../../Healper/Methods";
import { ReNotification } from "../../../../unity-ui-common/Components/ReNotification";
import ReSelect from "../../../../unity-ui-common/Components/ReFormFields/ReSelect";
import { daysOfWeek, numbersOneToTwentyEight } from "../../../../Healper/Mappings";
import ReusableForm from "../../../../unity-ui-common/Components/ReForm";
import { Uniview } from "../../../../API/Uniview";
import ReModal from "../../../../unity-ui-common/Components/ReModal";
import ReInput from "../../../../unity-ui-common/Components/ReFormFields/ReInput";

interface IQcModalOption {
  name?: string,
  visibility: boolean,
  action?: any,
}

function QcConfigurationDrawer(props: {
  visibility: boolean;
  setVisibility: Function;
  currEditingSponor: any;
  setCurrEditingSponor: Function;
}) {
  const { visibility, setVisibility, currEditingSponor } = props;
  const [form] = Form.useForm();

  const [allCategory, setAllCategory] = useState<any[]>();
  const [frequencyType, setFrequencyType] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const [qcConfigModalOptions, setQcConfigModalOptions] = useState<IQcModalOption>({
    visibility: false,
  });

  useEffect(() => {
    if (visibility === true) {
      setInitialValuesOfForm();
      fetchCategorisationData();
    }
  }, [visibility]);

  const setInitialValuesOfForm = () => {
    const defaultNotificationsValues =
      currEditingSponor.notification && typeof currEditingSponor.notification === "object" &&
      Object.keys(currEditingSponor.notification).map((key) => {
        let text = capitalizeWordsExceptExceptions(convertToSimpleCase(key), [
          "for",
          "in",
          "to",
          "is",
        ])
          .replace("I C S R", "ICSR")
          .replace("Email", "");
        return {
          name: text,
          emails: currEditingSponor.notification[key],
        };
      });
    const configs = JSON.parse(currEditingSponor?.configs || "{}");
    form.setFieldsValue({
      translate: currEditingSponor?.translate,
      aiCategorization: currEditingSponor?.aiCategorization,
      frequency: currEditingSponor?.qcConfigure?.frequency,
      qualityCheckConfig: currEditingSponor?.qcConfigure?.qualityCheckConfig || [],
      emailNotifications: defaultNotificationsValues,
      wfOneDays: configs?.wfOneDays,
      wfTwoDays: configs?.wfTwoDays,
      qcDays: configs?.qcDays,
      weeklyQcDay: currEditingSponor?.qcConfigure?.weeklyQcDay,
      monthQcDate: currEditingSponor?.qcConfigure?.monthQcDate,
    });
    setFrequencyType(currEditingSponor?.qcConfigure?.frequency);
  };

  const fetchCategorisationData = async () => {
    const univiewService = new Uniview("masterData", "categorization");
    const res = await univiewService.fetchData({ tenantId: { "=": currEditingSponor?.id } }, { orderBy: { name: "ASC" } });
    setAllCategory(res.data);
  }

  const handleFormChange = (changedValue: any) => {
    if (changedValue?.frequency) {
      setFrequencyType(changedValue?.frequency)
    }
  }

  const onFinish = async (values: any) => {
    // setSubmitting(true);
    let {
      aiCategorization,
      frequency,
      translate,
      wfOneDays,
      wfTwoDays,
      qcDays,
      qualityCheckConfig,
      weeklyQcDay,
      monthQcDate
    } = values;
    const notificationObj: any = {};
    if (values.emailNotifications) {
      values.emailNotifications.map((noti: any) => {
        const keyName = capitalizeSubstring(
          convertToCamelCase(noti.name),
          "Icsr"
        );
        notificationObj[`${keyName}Email`] = noti.emails;
      });
    }

    let payload = {
      notification: notificationObj,
      qcConfigure: {
        frequency,
        qualityCheckConfig,
        weeklyQcDay: frequency === "monthly" ? undefined : weeklyQcDay,
        monthQcDate: frequency === "weekly" ? undefined : monthQcDate,
      },
      aiCategorization,
      translate,
      configs: {
        wfOneDays,
        wfTwoDays,
        qcDays,
      },
    };
    delete values.wfOneDays;
    delete values.wfTwoDays;
    delete values.qcDays;
    let res = await tenant.update(currEditingSponor.id, payload);
    if (res?.status) {
      ReNotification({
        description: "Sponsor/MAH Configuration Updated",
        type: "success",
      });
    } else {
      ReNotification({
        description: "Error while updateing sponsor configuration",
        type: "error",
      });
    }
    setSubmitting(false);
    setVisibility(false);
  };

  const handleQcConfigDelete = () => {
    let qcConfigData = form.getFieldValue("qualityCheckConfig");
    qcConfigData.splice(qcConfigModalOptions.name, 1)
    form.setFieldsValue({
      qualityCheckConfig: qcConfigData,
    })
    setQcConfigModalOptions({ visibility: false })
  }

  const getCategorisationName = (position?: string) => {
    const qcConfigData = form.getFieldValue("qualityCheckConfig");
    return (position && qcConfigData?.[position]?.name) || "this";
  }

  const shouldModalOpen = (position: any) => {
    const qcConfigData = form.getFieldValue("qualityCheckConfig");
    if (!qcConfigData?.[position]?.name) return false
    return true
  }

  return (
    <div>
      <Drawer
        className="drawer"
        title={`${currEditingSponor?.name}'s Configuration`}
        width={600}
        placement="right"
        onClose={() => {
          setVisibility(false);
        }}
        open={visibility}
      // open={true}
      >
        <Styles.Body>
          <ReusableForm
            className="form"
            formInstance={form}
            onSubmit={onFinish}
            onChange={handleFormChange}
          >
            <div className="section">
{/*               <div className="heading">AI Categorisation/Translate</div> */}
{/*               <div className="transAndAi"> */}
              <div className="heading">Translate</div>
              <div className="workflow">
                <div style={{ display: "flex", placeItems: "center", columnGap: "0.5rem" }}>
                  <div>Translate Status</div>
                  <Form.Item
                    name="translate"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch checkedChildren="Enable" unCheckedChildren="Disable" />
                  </Form.Item>
                </div>
                {/* <div style={{ display: "flex", placeItems: "center", columnGap: "0.5rem" }}>
                  <div>AI Categorization Status</div>
                  <Form.Item
                    name="aiCategorization"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch checkedChildren="Enable" unCheckedChildren="Disable" />
                  </Form.Item>
                </div> */}
              </div>
            </div>

            <div className="section">
              <div className="heading">Key Performance Indicator (KPI)</div>
              <div className="workflow">
                <div>Workflow 1 <RightOutlined /> Day 0 + </div>
                <Form.Item name="wfOneDays" noStyle>
                  <ReInput
                    min={1}
                    label=""
                    name="wfOneDays"
                    type="number"
                    size="small"
                  />
                </Form.Item>
                <div>Calendar Days</div>
              </div>
              <div className="workflow">
                <div>Workflow 2 <RightOutlined /> Day 0 + </div>
                <Form.Item name="wfTwoDays" noStyle>
                  <ReInput
                    min={1}
                    label=""
                    name="wfTwoDays"
                    type="number"
                    size="small"
                  />
                </Form.Item>
                <div>Calendar Days</div>
              </div>
              <div className="workflow">
                <div>Quality Check <RightOutlined /> Day 0 + </div>
                <Form.Item name="qcDays" noStyle>
                  <ReInput
                    min={1}
                    label=""
                    name="qcDays"
                    type="number"
                    size="small"
                  />
                </Form.Item>
                <div>Calendar Days</div>
              </div>
            </div>

            <div className="section">
              <div className="heading">Quality Check Frequency</div>
              <div className="qualityCheckFrequency">
                <div>
                  <Form.Item name="frequency">
                    <Radio.Group>
                      <Radio value="immediate">Batchwise</Radio>
                      <Radio value="daily">Daily</Radio>
                      <Radio value="weekly">Weekly</Radio>
                      <Radio value="monthly">Monthly</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="optional">
                  {(frequencyType === "weekly" || frequencyType === "monthly") &&
                    <ReSelect
                      name={frequencyType === "weekly" ? "weeklyQcDay" : "monthQcDate"}
                      label=""
                      items={frequencyType === "weekly" ? daysOfWeek : numbersOneToTwentyEight}
                      placeholder={frequencyType === "weekly" ? "Select The Day" : "Select The Date"}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="section">
              <div className="heading">Quality Check Configuration</div>
              <div className="qualityCheck">
                <Form.List name="qualityCheckConfig">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field: any, index: any) => (
                        <div className="qcState" key={field.key}>
                          <div className="categoriesSelect">
                            <Form.Item
                              label=""
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                            >
                              <Select showSearch allowClear>
                                {allCategory?.map((cat: any) => {
                                  const { name } = cat;
                                  return (
                                    <Select.Option value={name} width={100}>
                                      {name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                          <Form.Item
                            name={[field.name, "percentage"]}
                            fieldKey={[field.fieldKey, "percentage"]}
                          >
                            <InputNumber min={0} max={100} controls={false} addonAfter={<>%</>} style={{ width: "90px" }} />
                          </Form.Item>
                          <Form.Item name={[field.name, "status"]} valuePropName="checked" style={{ width: "65px" }}>
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                          </Form.Item>
                          <Button
                            onClick={() => {
                              shouldModalOpen(field.name) ? setQcConfigModalOptions({
                                name: field.name,
                                visibility: true,
                              }) : remove(field.name)
                            }}
                            className="removeSectionBtn"
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          danger
                          onClick={() => add()}
                          className="addSectionBtn"
                          icon={<PlusOutlined />}
                          style={{ marginTop: "10px" }}
                        >
                          Add Categorization
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
            <ReModal
              title={`Delete ${getCategorisationName(qcConfigModalOptions?.name)} Categorization`}
              visibility={qcConfigModalOptions?.visibility}
              children={<>Are you sure you want to delete QC Configuration for {getCategorisationName(qcConfigModalOptions?.name)} Categorization?</>}
              onCancel={() => setQcConfigModalOptions({ visibility: false })}
              onOkay={handleQcConfigDelete}
            />
            <div className="section">
              <div className="heading">All Notifications</div>
              <div className="notification">
                <Form.List name="emailNotifications">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field: any, index: any) => (
                        <div key={field.key}>
                          <Form.Item
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                          >
                            <Select showSearch allowClear placeholder="Select A Categorisation">
                              {allCategory?.map((cat: any) => {
                                const { name } = cat;
                                return (
                                  <Select.Option value={name}>
                                    {name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "emails"]}
                            fieldKey={[field.fieldKey, "emails"]}
                          >
                            <Input placeholder="example.something.com,example1.something.com" />
                          </Form.Item>
                          <Button
                            onClick={() => remove(field.name)}
                            className="removeSectionBtn"
                            icon={<DeleteOutlined />}
                          />
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          danger
                          onClick={() => add()}
                          className="addSectionBtn"
                          icon={<PlusOutlined />}
                        >
                          Add Section
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting}>
                Submit
              </Button>
            </Form.Item>
          </ReusableForm>
        </Styles.Body>
      </Drawer>
    </div>
  );
}

export default QcConfigurationDrawer;
