import SearchAndGenerateReport from "../Routes/SearchAndGenerateReport/SearchAndGenerateReport";
import {
  ActiveIngredient,
  Login,
  PageNotFound,
  PrivilegesManagement,
  ProductManagement,
  UserManagement,
  DosageForms,
  RouteOfAdministration,
  OutCome,
  UnitOfMeasurement,
  Seriousness,
  Workspace,
  CustomizedReport,
  GenerateReport,
  PageInProgress,
  WorkAllocation,
  FTP,
} from "../Routes";
import Countries from "../Routes/ControlledVocabularies/Countries/Countries";
import DME from "../Routes/ControlledVocabularies/DME/Dme";
import IME from "../Routes/ControlledVocabularies/IME/Ime";
import Medra from "../Routes/ControlledVocabularies/Medra/Medra";
import Categorization from "../Routes/Masters/Categorization/Categorization";
import ImportData from "../Routes/Records/ImportData/ImportData";
import Profile from "../Routes/Profile/Profile";
import Support from "../Routes/Support/Support";
import CaseView from "../Routes/CaseView/CaseView";
import ViewUsers from "../Routes/ViewUsers/ViewUsers";
import Testing from "../Routes/Testing";
import AllRecords from "../Routes/Records/AllRecords/AllRecords";
import Dashboard from "../Routes/Dashboard/Dashboard";
import AuditLogs from "../Routes/AuditLogs/AuditLogs";

export type RoutesDataType = {
  path: string;
  element: JSX.Element;
  privilages?: string[];
};

const RoutesData: Array<RoutesDataType> = [
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/inprogress",
    element: <PageInProgress />,
  },
  {
    path: '/imports/auto-work-allocation',
    element: <WorkAllocation />,
    privilages: ["6"]
  },
  {
    path: '/imports/ftp-translation',
    element: <FTP />,
    privilages: ["11"]
  },
  {
    path: "/imports/manual-work-allocation",
    element: <ViewUsers />,
    privilages: ["6"]
  },
  {
    path: "/testing",
    element: <Testing />,
    privilages: ["6"]
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  // {
  //   path: "/privileges",
  //   element: <PrivilegesManagement />,
  // },
  {
    path: "/user-management/:pageName",
    element: <UserManagement />,
    privilages: ["1"],
  },
  {
    path: "/master/product",
    element: <ProductManagement />,
    privilages: ["14"],
  },
  {
    path: "/master/active-ingredient",
    element: <ActiveIngredient />,
    privilages: ["14"],
  },
  {
    path: "/master/categorization",
    element: <Categorization />,
    privilages: ["14"],
  },
  {
    path: "/controlled-vocabularies/dosage",
    element: <DosageForms />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/route-of-administration",
    element: <RouteOfAdministration />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/outcome",
    element: <OutCome />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/unit-of-measurement",
    element: <UnitOfMeasurement />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/seriousness",
    element: <Seriousness />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/ime",
    element: <IME />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/dme",
    element: <DME />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/medra",
    element: <Medra />,
    privilages: ["15"],
  },
  {
    path: "/controlled-vocabularies/country",
    element: <Countries />,
    privilages: ["15"],
  },
  {
    path: "/imports/import-data",
    element: <ImportData />,
    privilages: ["0"],
  },
  {
    path: "/imports/all-records",
    element: <AllRecords />,
    privilages: ["2"],
  },
  {
    path: "/imports/my-work",
    element: <AllRecords />,
    privilages: ["7", "8", "9"],
  },
  {
    path: "/imports/unassigned-records",
    element: <AllRecords />,
    privilages: ["2"],
  },
  {
    path: "/workspace",
    element: <Workspace />,
  },
  {
    path: "/search-and-generate-report",
    element: <SearchAndGenerateReport />,
    privilages: ["13"],
  },
  {
    path: "/search-and-generate-report-stand-alone",
    element: <SearchAndGenerateReport />,
  },
  {
    path: "/audit-trail",
    element: <AuditLogs/>,
    privilages: ["10"],
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/support",
    element: <Support />,
  },
  {
    path: "/customized-report",
    element: <CustomizedReport />,
    privilages: ["12"],
  },
  {
    path: "/generate-report",
    element: <GenerateReport />,
    privilages: ["12"],
  },
  {
    path: "/view-record/:caseId",
    element: <CaseView />,
  },
  {
    path: "/testing",
    element: <Testing />,
  },
];

export default RoutesData;
