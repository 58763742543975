import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Tabs,
} from "antd";
import * as Styles from "./WorkflowFormStyles";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { controlledVocabularies } from "../../../API/ControlledVocabularies/ContolledVocalbularies";
import { importEngine } from "../../../API/ImportEngine";
import moment from "moment";
import {
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { file } from "../../../API/File";
import TabPane from "rc-tabs/lib/TabPanelList/TabPane";
import CaseHistory from "../CaseHistory/CaseHistory";
import { IMEAndDMEOptions, ageGroupItems, ageTitlesItems } from "./Constant";
import { product } from "../../../API/Masters/Product/Product";
import debounce from "lodash/debounce";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import {
  CommonGenderFieldOptions,
  // CommonAgeOptions,
  CommonSubCategorizationOptions,
} from "../../../Configs/CommonFormFields";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReUpload from "../../../unity-ui-common/Components/ReFormFields/ReUpload";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import { StatusDropDown } from "../../../Routes/FTP/data";
import KPIHistory from "../KPIHistory/KPIHistory";
import AttachementList from "../AttachementList/AttachementList";
import { makeFirstLetterCapital } from "../../../unity-ui-common/Methods/Methods";
import {
  StageMappingByValue,
  StatusEnumToValueMapping,
  StatusValueToEnumMapping,
} from "../../../Healper/Mappings";
import { categorization } from "../../../API/Masters/Categorization/Categorization";
import { determineAgeGroup } from "../../../Healper/Methods";

function WorkflowForm(props: {
  setVisibility: Function;
  visibility: boolean;
  allFileData?: any;
  defaultTabId?: string;
  isViewRecordData?: boolean;
}) {
  let submitType = 'submit';
  const dispatch = useAppDispatch();
  const currentuser = JSON.parse(sessionStorage.getItem("user") || '{}');
  const allData = useAppSelector((store) => store.session);
  const { setVisibility, visibility, defaultTabId, isViewRecordData, allFileData } = props;

  const [form] = Form.useForm();
  const [ftpForm] = Form.useForm();

  const { allProduct } = useAppSelector((store) => store.product);
  const { currentAccessingCase: reduxStoredCaseData } = useAppSelector((store) => store.cases);
  const [currentAccessingCase, setCurrentAccessingCase] = useState<any>({});
  const { allRouteOfAdministration, allUnits, allCountries, allSeriousness } =
    useAppSelector((store) => store.controlledVocabularies);

  const [submitting, setSubmitting] = useState(false);
  const [searchMedraQuery, setSearchMedraQuery] = useState("");
  const [meddraCodingItems, setmeddraCodingItems] = useState([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [translateFileList, setTranslateFileList] = useState<any[]>([]);
  const [ftpFileList, setFtpFileList] = useState<any[]>([]);
  const [attachmentUploading, setAttachmentUploading] = useState<boolean>(false);
  const [attacmentData, setAttacmentData] = useState<any | null>(null);
  const [ftpAttacmentData, setFtpAttacmentData] = useState<any[]>([]);
  const [translateAttacmentData, settranslateAttacmentData] = useState<any[]>([]);
  const [levelFormTabsActiveId, setLevelFormTabsActiveId] = useState(defaultTabId || "1");
  const [isMLM, setIsMLM] = useState<boolean>();
  const [showErrorReason, setShowErrorReason] = useState<boolean>(false);
  const [errorIdentified, setErrorIdentified] = useState();
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [isDay0Change, setIsDay0Change] = useState(false);

  useEffect(() => {
    if (visibility) {
      Object.keys(currentAccessingCase).length > 0 && initialValuesOfForm();
    }
  }, [currentAccessingCase, allProduct]);

  useEffect(() => {
    if (visibility) {
      initialLoadApi();
      setCurrentAccessingCase(reduxStoredCaseData);
    }
  }, [reduxStoredCaseData]);

  useEffect(() => {
    if (!visibility) {
      setLevelFormTabsActiveId("1");
    }
    ftpForm.resetFields();
    setFtpAttacmentData([]);
    setFtpFileList([]);
    settranslateAttacmentData([]);
    setTranslateFileList([]);
  }, [visibility]);

  const onFinish = async (values: any) => {
    values.type = submitType;
    if (currentAccessingCase?.stage === 2) {
      values.type = "save";
    }
    submitType = 'submit';
    const isManagerSubmit = allData.userPermissions.includes("6") && levelFormTabsActiveId === '1';
    if (currentAccessingCase?.assignedUser !== currentuser?.user?.id && !isManagerSubmit) return;
    if (currentAccessingCase?.stage == "2" && !isManagerSubmit) return;
    values.dateOfSearch = moment(values?.dateOfSearch?._d)?.format(
      "DD-MMM-YYYY"
    );
    if (attacmentData) {
      values.attachment = attacmentData;
    }
    let tenantId = JSON.parse(
      sessionStorage.getItem("selectedWorkSpace") || '{"name": "", "id": ""}'
    );
    values["tenantId"] = tenantId?.id;
    values["isMLM"] = isMLM;
    if (isDay0Change) {
      values["day0ChangeReason"] = `${currentAccessingCase?.meta?.day0ChangeReason ? currentAccessingCase?.meta?.day0ChangeReason + "<|||>" : ""}${values["day0ChangeReason"]} - ${moment().format("DD-MMM-YYYY")}`
    }
    // when we set default eve and new eve objects
    values.event = values.event?.map((eve: any) => {
      values.reportData = [...(values.reportData || []), eve.eventTerm];
      values.seriousnessValue = [...(values.seriousnessValue || []), ...(eve.seriousness || [])];
      if (eve.hasOwnProperty("meddraObj")) {
        return {
          ...eve,
          meddraCoding: eve?.meddraObj.value,
          EventMeddraCodeName: eve?.meddraObj.title,
        };
      } else
        return {
          ...eve,
          meddraCoding: eve?.meddraCoding?.value,
        };
    });
    if (values?.product) {
      values.productRelatedData = values?.product?.map((pro: any) => {
        return pro.productName;
      });
    }
    console.log(values?.product, values.productRelatedData)
    setSubmitting(true);
    const res = await importEngine.createWorkFlowOne(
      values,
      currentAccessingCase.id
    );
    if(res.batchQcCheck){
      ReNotification({
        description: "Batch Records Moved To Quality Check Workflow",
        type: "success",
      });
    }
    if (res.status) {
      ReNotification({
        description: res.message,
        type: "success",
      });
      setSubmitting(false);
      setAttacmentData(null);
      setFileList([]);
      setVisibility(false);
    } else {
      ReNotification({
        description: res.message,
        type: "error",
      });
    }
  };

  const onFinishFTPTab = async (values: any) => {
    let payload: any = {
      caseId: currentAccessingCase?.id,
      ...(ftpAttacmentData.length || translateAttacmentData.length
        ? { attachments: [...ftpAttacmentData, ...translateAttacmentData] }
        : {}),
      ...(values?.caseVersionDateOfSearch
        ? {
          dateOfSearch: moment(values?.caseVersionDateOfSearch).format(
            "DD-MMM-YYYY"
          ),
        }
        : {}),
      ...(values?.ftpRequestStatus
        ? {
          ftpRequestStatus: StatusValueToEnumMapping(
            values?.ftpRequestStatus
          ).toString(),
        }
        : {}),
      ...(values?.dateFtpSent
        ? { dateFtpSent: moment(values?.dateFtpSent).toDate() }
        : {}),
      ...(values?.dateFtpReceived
        ? { dateFtpReceived: moment(values?.dateFtpReceived).toDate() }
        : {}),
      ...(values?.translationRequestStatus
        ? {
          translationRequestStatus: StatusValueToEnumMapping(
            values?.translationRequestStatus
          ).toString(),
        }
        : {}),
      ...(values?.dateTranslationSent
        ? { dateTranslationSent: moment(values?.dateTranslationSent).toDate() }
        : {}),
      ...(values?.dateTranslationReceived
        ? {
          dateTranslationReceived: moment(
            values?.dateTranslationReceived
          ).toDate(),
        }
        : {}),
    };
    setSubmitting(true);
    const changeFtpRes = await importEngine.changeFtpAndTranslation(payload);
    if (changeFtpRes?.status) {
      ReNotification({
        description: changeFtpRes?.message || "FTP/Translate Updates Done.",
        type: "success",
      });
    } else {
      ReNotification({
        description: changeFtpRes?.message || "Failed to Updates FTP/Translate Data.",
        type: "error",
      });
    }
    if (changeFtpRes?.status && payload?.attachments && [2, 3].includes(currentAccessingCase.stage)) {
      payload = {
        dateOfSearch: moment(values.caseVersionDateOfSearch).format(
          "DD-MMM-YYYY"
        ),
        stage: null,
        ...payload,
      };
      setSubmitting(true);
      const res = await importEngine.createVersion(
        currentAccessingCase?.id,
        payload
      );
      if (res?.status) {
        ReNotification({
          description: res?.message || "New Version is Created Successfully",
          type: "success",
        });
      } else {
        ReNotification({
          description: res?.message || "Failed to Created New Vesion.",
          type: "error",
        });
      }
    }
    setSubmitting(false);
  };

  const handleMeddraCodingSearch = async (query: string) => {
    setSearchMedraQuery(query);

    const res = await controlledVocabularies.getManyMedra(query);
    setmeddraCodingItems(res);
  };
  const onMeddraDropdownVisibleChange = (open: any) => {
    if (!open) {
      setmeddraCodingItems([]);
    }
  };

  const getSeriousnessCriteria = (ele: any) => {
    let seriousnessValue = [];
    const mapping = {
      E_i_3_2a_resultsInDeath: "deathfatal",
      E_i_3_2b_lifeThreatening: "lifethreatening",
      E_i_3_2c_causedProlongedHospitalisation: "hospitalizationinitialorprolonged",
      E_i_3_2d_disablingIncapacitating: "disabilityorpermanentdamage",
      E_i_3_2e_congenitalAnomalyBirthDefect: "congenitalanomalybirthdefect",
      E_i_3_2f_otherMedicallyImportantCondition: "otherseriousimportantmedicalevents",
    }
    ele.E_i_3_2a_resultsInDeath && seriousnessValue.push(mapping.E_i_3_2a_resultsInDeath)
    ele.E_i_3_2b_lifeThreatening && seriousnessValue.push(mapping.E_i_3_2b_lifeThreatening)
    ele.E_i_3_2c_causedProlongedHospitalisation && seriousnessValue.push(mapping.E_i_3_2c_causedProlongedHospitalisation)
    ele.E_i_3_2d_disablingIncapacitating && seriousnessValue.push(mapping.E_i_3_2d_disablingIncapacitating)
    ele.E_i_3_2e_congenitalAnomalyBirthDefect && seriousnessValue.push(mapping.E_i_3_2e_congenitalAnomalyBirthDefect)
    ele.E_i_3_2f_otherMedicallyImportantCondition && seriousnessValue.push(mapping.E_i_3_2f_otherMedicallyImportantCondition)
    return seriousnessValue;
  }

  const initialValuesOfForm = async () => {
    let defaultEventValues;
    let defaultProductValues;
    let productMapper: any = {};
    allProduct.forEach((product) => {
      productMapper[product.name] = product.id;
    });
    if (
      currentAccessingCase
        .E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary
        .constructor === Array
    ) {
      defaultEventValues =
        currentAccessingCase.E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary?.map(
          (ele: any, index: any) => {
            return {
              eventTerm:
                ele?.E_i_1_2_reactionEventAsReportedByThePrimarySourceForTranslation,
              meddraCoding: ele?.EventMeddraCodeName,
              seriousness: getSeriousnessCriteria(ele),
              medDraVersion: ele?.E_i_2_1a_meddraVersionForReactionEvent,
              meddraObj: {
                title: ele?.EventMeddraCodeName,
                value: ele?.E_i_2_1b_reactionEventMeddraCode,
              },
              ime: ele?.ime,
              dme: ele?.dme,
            };
          }
        );
    } else {
      const withNewKeys = {
        eventTerm:
          currentAccessingCase
            .E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary
            .E_i_1_2_reactionEventAsReportedByThePrimarySourceForTranslation,
        meddraCoding:
          currentAccessingCase
            .E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary
            .E_i_2_1b_reactionEventMeddraCode,
        seriousness: getSeriousnessCriteria(currentAccessingCase.E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary),
        medDraVersion:
          currentAccessingCase
            ?.E_i_reactioneventAsReportedByThePrimarySourceRepeatAsNecessary
            ?.E_i_2_1a_meddraVersionForReactionEvent,
      };
      defaultEventValues = [withNewKeys];
    }

    // finding default product value
    if (
      currentAccessingCase.G_k_4_r_dosageAndRelevantInformationRepeatAsNecessary
        .constructor === Array
    ) {

      defaultProductValues =
        await Promise.all(currentAccessingCase.G_k_4_r_dosageAndRelevantInformationRepeatAsNecessary?.map(
          async (ele: any, index: number) => {
            let productName = currentAccessingCase
              .D_8_r_relevantPastDrugHistoryRepeatAsNecessary[index]
              .D_8_r_1_nameOfDrugAsReported;
            const isMLM = productMapper[productName] ? await product.getProduct(productMapper[productName]) : "";
            return {
              productName: productName,
              routeOfAdministration:
                ele.G_k_4_r_10_1_routeOfAdministrationFreeText,
              strength:
                currentAccessingCase
                  .G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary[
                  index
                ].G_k_2_3_r_3a_strengthNumber,
              strengthUnit:
                currentAccessingCase
                  .G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary[
                  index
                ].G_k_2_3_r_3b_strengthUnit,
              ...productName ? { isMLM: isMLM ? "Yes" : "No" } : {},
            };
          }
        ));
    } else {
      const productName = currentAccessingCase.D_8_r_relevantPastDrugHistoryRepeatAsNecessary.D_8_r_1_nameOfDrugAsReported
      const isMLM = productMapper[productName] ? await product.getProduct(productMapper[productName]) : "";
      const withNewKeys = {
        productName: productName,
        routeOfAdministration:
          currentAccessingCase
            .G_k_4_r_dosageAndRelevantInformationRepeatAsNecessary
            .G_k_4_r_10_1_routeOfAdministrationFreeText,
        strength:
          currentAccessingCase
            .G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary
            .G_k_2_3_r_3a_strengthNumber,
        strengthUnit:
          currentAccessingCase
            .G_k_2_3_r_substanceSpecifiedSubstanceIdentifierAndStrengthRepeatAsNecessary
            .G_k_2_3_r_3b_strengthUnit,
        ...productName ? { isMLM: isMLM ? "Yes" : "No" } : {},

      };
      defaultProductValues = [withNewKeys];
    }
    const authorsValue = currentAccessingCase
      .C_2_r_primarySourcesOfInformationRepeatAsNecessary
      .C_2_r_1_2_reportersGivenName
      ? currentAccessingCase.C_2_r_primarySourcesOfInformationRepeatAsNecessary
        ?.C_2_r_1_2_reportersGivenName
      : "";
    form.setFieldsValue({
      searchStrategy: currentAccessingCase.searchStrategy,
      citation: currentAccessingCase.citation,
      dateOfSearch: currentAccessingCase.dateOfSearch
        ? moment(currentAccessingCase.dateOfSearch)
        : "",
      categorization: currentAccessingCase.categorization.map((item: any) =>
        Number(item)
      ),
      countryOfOrigin:
        currentAccessingCase.C_2_r_primarySourcesOfInformationRepeatAsNecessary
          .C_2_r_3_reportersCountryCode,
      gender: currentAccessingCase.D_5_sex,
      age:
        currentAccessingCase.D_2_2a_ageAtTimeOfOnsetOfReactionEventNumber,
      ageTitle:
        currentAccessingCase.D_2_2b_ageAtTimeOfOnsetOfReactionEventUnit,
      ageGroup: currentAccessingCase.D_2_3_patientAgeGroupAsPerReporter,
      reporter:
        currentAccessingCase.C_2_r_primarySourcesOfInformationRepeatAsNecessary
          .C_2_r_1_1_reportersTitle,
      comment: currentAccessingCase.comment,
      comments: currentAccessingCase.comments,
      event: defaultEventValues,  
      product: defaultProductValues,
      subCategorization: currentAccessingCase.subCategorization
        ? currentAccessingCase.subCategorization
        : [],
      authorName:
        currentAccessingCase?.source === "localLiterature"
          ? currentAccessingCase?.authors
          : authorsValue,
      translationRequired: currentAccessingCase.translationRequired,
      aiCategorization: currentAccessingCase.aiCategorization
        ? currentAccessingCase.aiCategorization.map((item: any) => Number(item))
        : [],
      FTPRequired: currentAccessingCase.ftpRequired,
    });
    ftpForm.setFieldsValue({
      ...(currentAccessingCase?.dateFtpSent
        ? { dateFtpSent: moment(currentAccessingCase.dateFtpSent) }
        : {}),
      ...(currentAccessingCase?.dateFtpReceived
        ? { dateFtpReceived: moment(currentAccessingCase?.dateFtpReceived) }
        : {}),
      ...(currentAccessingCase?.dateTranslationSent
        ? {
          dateTranslationSent: moment(
            currentAccessingCase?.dateTranslationSent
          ),
        }
        : {}),
      ...(currentAccessingCase?.dateTranslationReceived
        ? {
          dateTranslationReceived: moment(
            currentAccessingCase?.dateTranslationReceived
          ),
        }
        : {}),
    });
  };

  const setAgeGroupValue = (age: number, unit: string) => {
    if ((!age && age !== 0) || !unit) return null;
    const ageGroupData = determineAgeGroup(age, unit);
    form.setFieldValue("ageGroup", ageGroupData?.value);
  };

  const handleFormFieldValueChange = (changedValues: any, allValues: any) => {
    const changedValuesKeys = Object.keys(changedValues);
    if (changedValuesKeys.includes("dateOfSearch")) {
      if (moment(currentAccessingCase?.dateOfSearch).format("DD-MMM-YYYY") === moment(changedValues.dateOfSearch).format("DD-MMM-YYYY")) {
        setIsDay0Change(false);
      }
      else {
        setIsDay0Change(true);
      }
    }
    if(changedValuesKeys.includes("countryOfOrigin") && changedValues?.countryOfOrigin === undefined){
      form.setFieldValue("countryOfOrigin", null);
    }
    if (changedValuesKeys.includes("age") || changedValuesKeys.includes("ageTitle")) {
      setAgeGroupValue(allValues.age, allValues.ageTitle);
    }
    if (changedValuesKeys.includes("gender") && changedValues?.gender === undefined) {
      form.setFieldValue("gender", null);
    }
    if (changedValuesKeys.includes("ageTitle") && changedValues?.ageTitle === undefined) {
      form.setFieldValue("ageTitle", '');
    }
    if (changedValuesKeys.includes("ageGroup") && changedValues?.ageGroup === undefined) {
      form.setFieldValue("ageGroup", null);
    }
  };

  const handleMeddraCodingValueClear = (index: number) => {
    form.getFieldValue("event")[index] = {
      ...form.getFieldValue("event")[index],
      ime: "",
      dme: "",
    };
    form.setFieldValue(
      "event",
      form.getFieldValue("event")?.map((eve: any, i: number) =>
        i === index
          ? {
            ...eve,
            ime: "",
            dme: "",
            meddraObj: {
              title: "",
              value: null
            }
          }
          : { ...eve }
      )
    );
  };

  const handleMeddraCodingSelectValueChange = async (
    changedValues: any,
    allValues: any
  ) => {
    const res = await controlledVocabularies.searchMedraCoding(
      changedValues.value
    );

    form.setFieldValue(
      "event",
      form.getFieldValue("event")?.map((eve: any) =>
        eve.meddraCoding.value == changedValues.value
          ? {
            ...eve,
            ime: res.ime,
            EventMeddraCodeName: changedValues.label,
            meddraObj: {
              title: changedValues.label,
              value: changedValues.value
            },
            medDraVersion: '27.1',
            dme: res.dme,
          }
          : { ...eve }
      )
    );
  };

  const handleProductNameSelectValueChange = async (
    value: string,
    option: any
  ) => {
    console.log("HI")
    const res = await product.getProduct(value);
    setIsMLM(res);
    form.setFieldValue(
      "product",
      form.getFieldValue("product")?.map((pro: any) =>
        pro.productName === value
          ? {
            ...pro,
            isMLM: res ? "Yes" : "No",
            productName: option.children,
          }
          : { ...pro }
      )
    );
  };

  const handleProductNameSelectValueClear = (index: any) => {
    form.setFieldValue(
      "product",
      form.getFieldValue("product")?.map((pro: any, i: number) =>
        i === index
          ? {
            ...pro,
            isMLM: "",
            productName: "",
          }
          : { ...pro }
      )
    );
  };
  
  const initialLoadApi = async () => {
    setAllCategory((await categorization.getAll())?.data);
  }

  const handleBeforeUploadFTP = async (fileObj: any) => {
    setAttachmentUploading(true);
    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setFtpFileList([...ftpFileList, fileObj]);
    await file.remoteUploadFile(res.url, fileObj);

    setFtpAttacmentData([
      ...ftpAttacmentData,
      {
        fileUID: fileObj.uid,
        id: res.id,
        filename: fileName,
        file_type,
        type: "ftp",
      },
    ]);
    setAttachmentUploading(false);
    return true;
  };

  const handleBeforeUploadTranslate = async (fileObj: any) => {
    setAttachmentUploading(true);
    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];
    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    setTranslateFileList([...translateFileList, fileObj]);
    await file.remoteUploadFile(res.url, fileObj);

    settranslateAttacmentData([
      ...translateAttacmentData,
      {
        fileUID: fileObj.uid,
        id: res.id,
        filename: fileName,
        file_type,
        type: "translate",
      },
    ]);
    setAttachmentUploading(false);
    return true;
  };

  const handleRadioChange = (e: any) => {
    setShowErrorReason(e.target.value === "yes" ? true : false);
    setErrorIdentified(e.target.value);
  };

  function handleFormDisable(): boolean {
    if (isViewRecordData) {
      return true;
    }
    else if (allData.userPermissions.includes("6") && levelFormTabsActiveId === '1' && isDay0Change) {
      return false;
    }
    else if ([null, 2].includes(currentAccessingCase.stage)) {
      return true;
    }
    else if (currentAccessingCase?.assignedUser === currentuser?.user?.id) {
      return false;
    }
    else {
      return true;
    }
  }

  return (
    <Styles.Container>
      <ReusableForm
        formInstance={form}
        onSubmit={onFinish}
        onChange={handleFormFieldValueChange}
        className="form"
        disable={
          [null, 2].includes(currentAccessingCase.stage) ||
          currentAccessingCase.assignedUser !== currentuser?.user?.id
        }
      >
        <Tabs
          className="tabBar"
          defaultActiveKey={levelFormTabsActiveId}
          activeKey={levelFormTabsActiveId}
          onChange={(id) => setLevelFormTabsActiveId(id)}
          tabBarExtraContent={
            <Form.Item noStyle>
              {levelFormTabsActiveId === '7' ?
                <Button
                  className="submit-btn"
                  type="default"
                  disabled={[null, 2].includes(currentAccessingCase.stage) && levelFormTabsActiveId !== '7'}
                  loading={submitting}
                  onClick={() => { currentuser?.permissions?.includes("11") && ftpForm.submit(); }}
                >
                  Submit
                </Button>
                :
                <Dropdown.Button
                  icon={<DownOutlined />}
                  loading={submitting}
                  menu={{
                    items: [
                      {
                        label: 'Save Record Data',
                        key: 'save',
                      },
                    ],
                    onClick: () => { submitType = 'save'; form.submit(); },
                  }}
                  htmlType="submit"
                  placement="bottomLeft"
                  disabled={handleFormDisable()}
                >
                  Submit
                </Dropdown.Button>
              }
            </Form.Item>
          }
        >
          <TabPane key="1" tab="General">
            <ReInput
              label="Search Strategy"
              name="searchStrategy"
              type="simple"
            />
            <ReInput label="Citation" name="citation" type="simple" />
            <ReDatePicker
              label="Day 0"
              name="dateOfSearch"
              dateFormat="DD-MMM-YYYY"
              featureDates
              type="simple"
              width="100%"
              required
              disable={isViewRecordData || !allData.userPermissions.includes("6")}
            />
            {isDay0Change && <ReInput
              label="Day 0 Change Reason"
              name="day0ChangeReason"
              type="textArea"
              required
              disable={isViewRecordData || !allData.userPermissions.includes("6")}
            />}
            {/* <ReSelect
              label="AI Categorization"
              name="aiCategorization"
              items={allCategory?.length ? allCategory
                ?.map((item) => {
                  return {
                    title: item.name,
                    value: item.numid,
                    disable: item.status === 0
                  };
                }) : []}
              disable={true}
              type="multiple"
              searchable
            /> */}
            <ReSelect
              label="Record Categorization"
              name="categorization"
              items={allCategory?.length ? allCategory
                ?.map((item) => {
                  return {
                    title: item.name,
                    value: item.numid,
                    disable: item.status === 0
                  };
                }) : []}
              type="multiple"
              required={currentAccessingCase?.stage === 0}
              searchable
            />
            {currentAccessingCase.stage !== 0 && (
              <ReSelect
                label="Sub Categorization"
                name="subCategorization"
                items={CommonSubCategorizationOptions}
                type="multiple"
                searchable
              />
            )}
            <ReInput label="Narrative" name="comment" type="textArea" />
            <ReInput label="Comment" name="comments" type="textArea" />
            <AttachementList allFileData={allFileData} />
            {/* {currentAccessingCase.stage == 3 && (
              <>
                <h2>Quality Check</h2>
                <div className="qc-radio-btn">
                  <p>Error Identified</p>
                  <Radio.Group
                    optionType="button"
                    value={errorIdentified}
                    onChange={(e) => {
                      handleRadioChange(e);
                    }}
                  >
                    <Radio.Button key="yes" value={"yes"}>
                      Yes
                    </Radio.Button>
                    <Radio.Button key="no" value={"no"}>
                      No
                    </Radio.Button>
                  </Radio.Group>
                </div>
                {showErrorReason && (
                  <ReInput
                    label="Specify Error "
                    name="errorSpecification"
                    type="textArea"
                  />
                )}
              </>
            )} */}
          </TabPane>
          <TabPane key="2" tab="Patient">
            <div className="ageAndTitle">
              <ReInput label="Age" name="age" type="number"/>
              {/* <ReSelect
                label="Age"
                name="age"
                items={CommonAgeOptions}
                searchable
                required
              /> */}
              <ReSelect
                label="Age Unit"
                name="ageTitle"
                items={ageTitlesItems?.map((tit: any) => {
                  return {
                    value: tit,
                    title: makeFirstLetterCapital(tit),
                  };
                })}
                searchable
                // required
              />
            </div>
            <div className="twoColumn">
              <ReSelect
                label="Gender"
                name="gender"
                items={CommonGenderFieldOptions}
                searchable
                // required
              />
              <ReSelect
                label="Age Group"
                name="ageGroup"
                items={ageGroupItems}
                searchable
                // required
              />
            </div>
          </TabPane>
          <TabPane key="3" tab="Event">
            <Form.List name="event">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field: any, index: number) => (
                    <div key={field.key}>
                      <h2>Event {index + 1}</h2>
                      <Form.Item
                        label="Reported Term"
                        name={[field.name, "eventTerm"]}
                        fieldKey={[field.fieldKey, "eventTerm"]}
                      >
                        <Input />
                      </Form.Item>
                      <div className="imeAndDme">
                        <Form.Item
                          name={[field.name, "meddraCoding"]}
                          label="Event MedDRA coding-LLT"
                          fieldKey={[field.fieldKey, "meddraCoding"]}
                        >
                          <Select
                            onClear={() => {
                              handleMeddraCodingValueClear(index);
                            }}
                            allowClear
                            showSearch
                            value={searchMedraQuery}
                            placeholder="Select a value"
                            filterOption={false}
                            onSearch={debounce(handleMeddraCodingSearch, 2000)}
                            onDropdownVisibleChange={
                              onMeddraDropdownVisibleChange
                            }
                            onSelect={handleMeddraCodingSelectValueChange}
                            labelInValue
                          >
                            {meddraCodingItems?.map((item: any) => (
                              <Select.Option
                                key={item.lltCode}
                                value={item.lltCode}
                              >
                                {item.lltName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "ime"]}
                          label="IME"
                          fieldKey={[field.fieldKey, "ime"]}
                        >
                          <Select options={IMEAndDMEOptions} disabled />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "dme"]}
                          label="DME"
                          fieldKey={[field.fieldKey, "dme"]}
                        >
                          <Select options={IMEAndDMEOptions} disabled />
                        </Form.Item>
                      </div>
                      <Form.Item
                        label="MedDRA Version"
                        name={[field.name, "medDraVersion"]}
                        fieldKey={[field.fieldKey, "medDraVersion"]}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        label="Seriousness Criteria"
                        name={[field.name, "seriousness"]}
                        fieldKey={[field.fieldKey, "seriousness"]}
                      >
                        <Select showSearch allowClear mode="multiple">
                          {allSeriousness?.map((item: any) => {
                            const { name, slug } = item;
                            return (
                              <Select.Option value={slug}>{name}</Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Button
                        onClick={() => remove(field.name)}
                        className="removeSectionBtn"
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      danger
                      onClick={() => add()}
                      className="addSectionBtn"
                      icon={<PlusOutlined />}
                    >
                      Event
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </TabPane>
          <TabPane key="4" tab="Product">
            <Form.List name="product">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field: any, index: number) => (
                    <div key={field.key}>
                      <h2>Product {index + 1}</h2>
                      <div className="twoColumn">
                        <Form.Item
                          label="Product Name"
                          name={[field.name, "productName"]}
                          fieldKey={[field.fieldKey, "productName"]}
                        >
                          <Select
                            showSearch
                            allowClear
                            onClear={() =>
                              handleProductNameSelectValueClear(index)
                            }
                            onSelect={handleProductNameSelectValueChange}
                          >
                            {allProduct?.map((item: any) => {
                              const { name, id, status } = item;
                              if (status === 0) return;
                              return (
                                <Select.Option value={id}>{name}</Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="MLM"
                          name={[field.name, "isMLM"]}
                          fieldKey={[field.fieldKey, "isMLM"]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name={[field.name, "routeOfAdministration"]}
                        label="Route of Administration"
                        fieldKey={[field.fieldKey, "routeOfAdministration"]}
                      >
                        <Select showSearch allowClear>
                          {allRouteOfAdministration?.map((item: any) => {
                            const { label } = item;
                            return (
                              <Select.Option value={label}>
                                {label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Strength"
                        name={[field.name, "strength"]}
                        fieldKey={[field.fieldKey, "strength"]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "strengthUnit"]}
                        label="Unit of Measurement"
                        fieldKey={[field.fieldKey, "strengthUnit"]}
                      >
                        <Select showSearch allowClear>
                          {allUnits?.map((item: any) => {
                            const { label } = item;
                            return (
                              <Select.Option value={label}>
                                {label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      <Button
                        onClick={() => remove(field.name)}
                        className="removeSectionBtn"
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      danger
                      onClick={() => add()}
                      className="addSectionBtn"
                      icon={<PlusOutlined />}
                    >
                      Product Section
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </TabPane>
          <TabPane key="5" tab="Reporter">
            <ReSelect
              label="Reporter Country"
              name="countryOfOrigin"
              items={allCountries?.map((cou) => {
                return {
                  title: cou.name,
                  value: cou.name,
                };
              })}
              searchable
            />
            <ReInput label="Primary Reporter" name="reporter" type="simple" />
          </TabPane>
          {
            currentuser?.permissions?.includes("11") &&
            <TabPane key="7" tab="FTP/Translation">
              <ReusableForm
                formInstance={ftpForm}
                onSubmit={onFinishFTPTab}
                disable={false}
                className="ftp-form"
              >
                {[2, 3].includes(currentAccessingCase?.stage) &&
                  (currentAccessingCase?.ftpRequestStatus == 1 ||
                    currentAccessingCase?.translationRequestStatus == 1) && (
                    <div>
                      <h3>
                        {" "}
                        The record is currently{" "}
                        {StageMappingByValue(currentAccessingCase?.stage).text}, a
                        new version will be created.
                      </h3>
                      <ReDatePicker
                        label="Day 0"
                        name="caseVersionDateOfSearch"
                        dateFormat="DD-MMM-YYYY"
                        featureDates
                        type="simple"
                        width="100%"
                      />
                    </div>
                  )}
                <div>
                  <h3>Full Text Publication Request</h3>
                  <h4>
                    Current Status:{" "}
                    {StatusEnumToValueMapping(
                      currentAccessingCase?.ftpRequestStatus
                    ) || "NA"}
                  </h4>
                  {currentAccessingCase?.ftpRequestStatus != "2" && (
                    <ReSelect
                      label="Full Text Publication Status"
                      name="ftpRequestStatus"
                      items={[
                        StatusDropDown[
                        currentAccessingCase?.ftpRequestStatus
                          ? parseInt(currentAccessingCase?.ftpRequestStatus) + 1
                          : 0
                        ],
                      ]}
                      searchable
                    />
                  )}
                  {parseInt(currentAccessingCase?.ftpRequestStatus) >= 0 && (
                    <ReDatePicker
                      label="FTP Request Sent Date"
                      name="dateFtpSent"
                      dateFormat="DD-MMM-YYYY"
                      featureDates
                      type="simple"
                      width="100%"
                      disable={currentAccessingCase?.ftpRequestStatus >= 1}
                    />
                  )}
                  {parseInt(currentAccessingCase?.ftpRequestStatus) >= 1 && (
                    <>
                      <ReDatePicker
                        label="FTP Request Received Date"
                        name="dateFtpReceived"
                        dateFormat="DD-MMM-YYYY"
                        featureDates
                        lastDate={currentAccessingCase?.dateFtpSent}
                        type="simple"
                        width="100%"
                        disable={currentAccessingCase?.ftpRequestStatus == 2}
                      />
                      {currentAccessingCase?.ftpRequestStatus != 2 && (
                        <ReUpload
                          label="Upload Full Text Publication File"
                          name="attacmentData"
                          BtnTitle="Upload"
                          onBeforeUpload={handleBeforeUploadFTP}
                          onRemove={(fileObj: any) => {
                            const index = ftpFileList.indexOf(fileObj);
                            const newFileList = ftpFileList.slice();
                            newFileList.splice(index, 1);
                            setFtpFileList(newFileList);
                            const index2 = ftpAttacmentData.indexOf(fileObj);
                            const newFileList2 = ftpAttacmentData.slice();
                            newFileList2.splice(index2, 1);
                            setFtpAttacmentData(newFileList2);
                          }}
                          fileList={ftpFileList}
                          disable={attachmentUploading}
                          fileListMaxCount={20}
                          errorMessage={"Error"}
                        />
                      )}
                    </>
                  )}
                </div>
                <Divider plain />
                <div>
                  <h3>Translation Request</h3>
                  <h4>
                    Current Status:{" "}
                    {StatusEnumToValueMapping(
                      currentAccessingCase?.translationRequestStatus
                    ) || "NA"}
                  </h4>
                  {currentAccessingCase?.translationRequestStatus != "2" && (
                    <ReSelect
                      label="Translation Status"
                      name="translationRequestStatus"
                      items={[
                        StatusDropDown[
                        currentAccessingCase?.translationRequestStatus
                          ? parseInt(
                            currentAccessingCase?.translationRequestStatus
                          ) + 1
                          : 0
                        ],
                      ]}
                      searchable
                    />
                  )}
                  {parseInt(currentAccessingCase?.translationRequestStatus) >=
                    0 && (
                      <ReDatePicker
                        label="Translation Request Sent Date"
                        name="dateTranslationSent"
                        dateFormat="DD-MMM-YYYY"
                        featureDates
                        type="simple"
                        width="100%"
                        disable={
                          currentAccessingCase?.translationRequestStatus >= 1
                        }
                      />
                    )}
                  {parseInt(currentAccessingCase?.translationRequestStatus) >=
                    1 && (
                      <>
                        <ReDatePicker
                          label="Translation Request Received Date"
                          name="dateTranslationReceived"
                          dateFormat="DD-MMM-YYYY"
                          featureDates
                          lastDate={currentAccessingCase?.dateTranslationSent}
                          type="simple"
                          width="100%"
                          disable={
                            currentAccessingCase?.translationRequestStatus == 2
                          }
                        />
                        {currentAccessingCase?.translationRequestStatus != 2 && (
                          <ReUpload
                            label="Upload Translation File"
                            name="translateUploadFiles"
                            BtnTitle="Upload"
                            onBeforeUpload={handleBeforeUploadTranslate}
                            onRemove={(fileObj: any) => {
                              const index = translateFileList.indexOf(fileObj);
                              const newFileList = translateFileList.slice();
                              newFileList.splice(index, 1);
                              setTranslateFileList(newFileList);
                              const index2 =
                                translateAttacmentData.indexOf(fileObj);
                              const newFileList2 = translateAttacmentData.slice();
                              newFileList2.splice(index2, 1);
                              settranslateAttacmentData(newFileList2);
                            }}
                            fileList={translateFileList}
                            disable={attachmentUploading}
                            fileListMaxCount={20}
                            errorMessage={"Error"}
                          />
                        )}
                      </>
                    )}
                </div>
              </ReusableForm>
            </TabPane>
          }
          <TabPane key="8" tab="Record History">
            <KPIHistory />
            <CaseHistory />
            <br />
          </TabPane>
        </Tabs>
      </ReusableForm>
    </Styles.Container>
  );
}

export default WorkflowForm;
