import { Select } from "antd";

export const subCategorization = [
  "Lack of Efficacy",
  "Overdose",
  "Misuse",
  "Abuse",
  "Special Populations",
  "Off Label Use",
  "Medication Error",
  "New Benefit Risk Assessment",
  "Product dependency",
    "Occupational exposure",
    "Drug Interaction",
    "Class Effects",
    "Counterfeit",
    "Suspected transmission of an infectious agent via a medicinal product",
    "Exposure in-utero or exposure during pregnancy",
    "Neonatal exposure via breast milk",
    "Compassionate supply, named patient use",
    "Important non-clinical safety results",
  "Other",
];

export const ageGroupItems = [
  {
    value: 0,
    title: "Foetus",
  },
  {
    value: 1,
    title: "Neonate",
  },
  {
    value: 2,
    title: "Infant",
  },
  {
    value: 3,
    title: "Child",
  },
  {
    value: 4,
    title: "Adolescent",
  },
  {
    value: 5,
    title: "Adult",
  },
  {
    value: 6,
    title: "Elderly",
  },
];

export const CommonCategorizationOptions = [
  {
    value: 0,
    title: "Valid ICSR"
  },
  {
    value: 1,
    title: "Potential ICSR"
  },
  {
    value: 2,
    title: "Non-Relevant Publication"
  },
  {
    value: 3,
    title: "Potentially Relevant for Safety"
  },
  {
    value: 4,
    title: "Aggregate Report Relevant"
  },
  {
    value: 5,
    title: "Potentially Relevant for Signaling"
  },

  {
    value: 6,
    title: "Product Quality Complaint"
  },
];

export const CommonSubCategorizationOptions = [
  {
    value: "Lack of Efficacy",
    title: "Lack of Efficacy"  
  },
  {
    value: "Overdose",
    title: "Overdose"  
  },
  {
    value: "Misuse",
    title: "Misuse"  
  },
  {
    value: "Abuse",
    title: "Abuse"  
  },
  {
    value: "Special Populations",
    title: "Special Populations"  
  },
  {
    value: "Off Label Use",
    title: "Off Label Use"    
  },
  {
    value: "Medication Error",
    title: "Medication Error"  
  },
  {
    value: "New Benefit Risk Assessment",
    title: "New Benefit Risk Assessment"  
  },
 
  {
    value: "Other",
    title: "Other"  
  },
];
const condition = true; // Replace this with your actual condition
const tenantData = JSON.parse(sessionStorage.getItem("selectedWorkSpace") || '{"name": "", "id": ""}')
let updatedOptions;
if (tenantData.name == 'Tolmar, Inc') {
  while (CommonSubCategorizationOptions.length > 0) {
    CommonSubCategorizationOptions.pop();
  }
  }

if (tenantData.name == 'Tolmar, Inc') {
  CommonSubCategorizationOptions.push( {
    value: "Lack of efficacy",
    title: "Lack of efficacy"  
  },
  {
    value: "Asymptomatic overdose, abuse or misuse, product dependency, occupational exposur",
    title: "Asymptomatic overdose, abuse or misuse, product dependency, occupational exposur"  
  },
  {
    value: "Medication error",
    title: "Medication error"  
  },
  {
    value: "Off-label use",
    title: "Off-label use"  
  },
  {
    value: "Drug Interaction",
    title: "Drug Interaction"  
  },
  {
    value: "Class Effects",
    title: "Class Effects"  
  },
  {
    value: "Use of a Product in a pediatric or elderly population",
    title: "Use of a Product in a pediatric or elderly population"  
  },
  {
    value: "Suspected adverse reactions to quality defect or falsified medicinal Product (counterfeit)",
    title: "Suspected adverse reactions to quality defect or falsified medicinal Product (counterfeit)"  
  },
  {
    value: "Suspected transmission of an infectious agent via a medicinal product",
    title: "Suspected transmission of an infectious agent via a medicinal product"  
  },
  {
    value: "Exposure in-utero or exposure during pregnancy",
    title: "Exposure in-utero or exposure during pregnancy"  
  },
  {
    value: "Neonatal exposure via breast milk",
    title: "Neonatal exposure via breast milk"  
  },
  {
    value: "Compassionate supply, named patient use",
    title: "Compassionate supply, named patient use"  
  },
  {
    value: "Important non-clinical safety results",
    title: "Important non-clinical safety results"  
  },
  {
    value: "Other potentially relevant new safety information",
    title: "Other potentially relevant new safety information"  
  }

);
}


export const CommonGenderFieldOptions = [
  {
    value: 0,
    title: "Male"  
  },
  {
    value: 1,
    title: "Female"  
  },
  {
    value: 2,
    title: "Other"  
  },
  {
    value: 3,
    title: "Unknown"  
  }
];
// export const CommonAgeOptions = [
//   { value: -1, title: 'UNK' },
//   ...Array.from({ length: 151 }, (_, i) => ({ value: i, title: i.toString() }))
// ];

export const CommonAgeGroupFieldOptions = [
  {
    value: 0,
    title: "Foetus",
  },
  {
    value: 1,
    title: "Neonate",
  },
  {
    value: 2,
    title: "Infant",
  },
  {
    value: 3,
    title: "Child",
  },
  {
    value: 4,
    title: "Adolescent",
  },
  {
    value: 5,
    title: "Adult",
  },
  {
    value: 6,
    title: "Elderly",
  },
];

export const CommonSeriousnessField = (allSeriousness: any) => {
  return (
    <Select showSearch mode="multiple" allowClear>
      {allSeriousness?.map((item: any) => {
        const { value, title } = item;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};

export const CommonProductNameFeild = (allProduct: any) => {
  return (
    <Select showSearch allowClear>
      {allProduct?.map((item: any) => {
        const { title, value } = item;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};

export const CommonCountryOfOriginFeild = (allCountries: any) => {
  return (
    <Select showSearch allowClear>
      {allCountries?.map((co: any) => {
        const { title, value } = co;
        return <Select.Option value={value}>{title}</Select.Option>;
      })}
    </Select>
  );
};