import {
  ControlOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  FileOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  PicCenterOutlined,
  ProjectOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

export const items = [
  {
    key: "1",
    Icon: <DashboardOutlined />,
    title: "Dashboard",
    url: "/",
  },
  {
    key: "sub-1",
    Icon: <PicCenterOutlined />,
    title: "Literature Records",
    subItems: [
      {
        key: "2",
        url: "/imports/import-data",
        title: "Import Data",
        priviliges: ["0"],
      },
      {
        key: "3",
        url: "/imports/all-records",
        title: "All Records",
        priviliges: ["2"],
      },
      {
        key: "4",
        url: "/imports/my-work",
        title: "My Work",
        priviliges: ["7", "8", "9"],
      },
      {
        key: "5",
        url: "/imports/auto-work-allocation",
        title: "Work Allocation",
        priviliges: ["6"],
      },
      {
        key: "29",
        url: "/imports/ftp-translation",
        title: "FTP/Translation",
        priviliges: ["11"],
      },
    ],
  },
  {
    key: "sub-5",
    Icon: <FileOutlined />,
    title: "Report",
    subItems: [
      {
        key: "23",
        url: "/customized-report",
        title: "Cumulative Raw Data Report",
        priviliges: ["12"],
      },
      {
        key: "24",
        url: "/generate-report",
        title: "Generate Report",
        priviliges: ["12"],
      },
    ],
  },
  {
    key: "22",
    Icon: <FileSearchOutlined />,
    title: "Search",
    url: "/search-and-generate-report",
    priviliges: ["13"],
  },
  {
    key: "25",
    url: "/audit-trail",
    Icon: <FileSyncOutlined />,
    title: "Audit Trail",
    priviliges: ["10"],
  },
  {
    key: "sub-2",
    Icon: <UserSwitchOutlined />,
    title: "User Management",
    subItems: [
      {
        key: "6",
        url: "/user-management/sponsors",
        title: "Sponsors",
        priviliges: ["1"],
      },
      {
        key: "7",
        url: "/user-management/roles",
        title: "User Roles",
        priviliges: ["1"],
      },
      {
        key: "8",
        url: "/user-management/users",
        title: "Users",
        priviliges: ["1"],
      },
    ],
  },
  {
    key: "sub-3",
    Icon: <ProjectOutlined />,
    title: "Master",
    subItems: [
      {
        key: "9",
        url: "/master/product",
        title: "Product",
        priviliges: ["14"],
      },
      {
        key: "10",
        url: "/master/active-ingredient",
        title: "Active Ingredient",
        priviliges: ["14"],
      },
      {
        key: "12",
        url: "/master/categorization",
        title: "Categorization",
        priviliges: ["14"],
      },
    ],
  },
  {
    key: "sub-4",
    Icon: <ControlOutlined />,
    title: "Controlled Vocabularies",
    subItems: [
      {
        key: "13",
        url: "/controlled-vocabularies/dosage",
        title: "Dosage Forms",
        priviliges: ["15"],
      },
      {
        key: "14",
        url: "/controlled-vocabularies/route-of-administration",
        title: "Route Of Administration",
        priviliges: ["15"],
      },
      {
        key: "15",
        url: "/controlled-vocabularies/unit-of-measurement",
        title: "Unit Of Measurement",
        priviliges: ["15"],
      },
      {
        key: "16",
        url: "/controlled-vocabularies/outcome",
        title: "Outcome",
        priviliges: ["15"],
      },
      {
        key: "17",
        url: "/controlled-vocabularies/seriousness",
        title: "Seriousness",
        priviliges: ["15"],
      },
      {
        key: "18",
        url: "/controlled-vocabularies/ime",
        title: "IME",
        priviliges: ["15"],
      },
      {
        key: "19",
        url: "/controlled-vocabularies/dme",
        title: "DME",
        priviliges: ["15"],
      },
      {
        key: "20",
        url: "/controlled-vocabularies/medra",
        title: "MedDra",
        priviliges: ["15"],
      },
      {
        key: "21",
        url: "/controlled-vocabularies/country",
        title: "Country",
        priviliges: ["15"],
      },
    ],
  },
  {
    key: "28",
    Icon: <CustomerServiceOutlined />,
    title: "Support",
    url: "/support",
  },
];
