import { Drawer, Switch, Form, SelectProps, InputNumber } from "antd";
import { useEffect, useState } from "react";
import "../Management/ManageUsers/styles.css";
import { tenant } from "../../API/UserManagement/Tenant";
import { getAllSponsors } from "../../slices/Sponsors/Helpers/GetAllSponsors";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllCountries } from "../../slices/ControlledVocabularies/Helpers/getAllCountries";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import * as Styles from "./SponserCreationFormStyles";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

export const SponserCreationForm = (props: {
  drawerType: string;
  visibility: boolean;
  setVisibility: Function;
  currEditingSponor: any;
  setCurrEditingSponor: Function;
}) => {
  const [form] = Form.useForm();
  const {
    visibility,
    setVisibility,
    currEditingSponor,
    drawerType,
    setCurrEditingSponor,
  } = props;
  const dispatch = useAppDispatch();
  const { allCountries } = useAppSelector(
    (store) => store.controlledVocabularies
  );

  const onFinish = async (values: any) => {
    values["timeZone"] = "IST";
    values.status = values.status === true ? 1 : 0;
    values.meta = {
      givenName: values.givenName,
      familyName: values.familyName,
      senderInterchangeId: values.senderInterchangeId,
      receiverInterchangeId: values.receiverInterchangeId || values.senderInterchangeId,
    }
    delete values.givenName;
    delete values.familyName;
    delete values.senderInterchangeId;
    delete values.receiverInterchangeId;
    delete values.wfOneDays;
    delete values.wfTwoDays;
    delete values.qcDays;
    const res = drawerType === "create" ? await tenant.create(values) : await tenant.update(currEditingSponor.id, values);
    if (res.status) {
      ReNotification({
        description: `Sponsor/MAH ${drawerType === "create" ? "Created" : "Updated"} Successfully`,
        type: "success",
      });
      dispatch(getAllSponsors());
      onClose();
    } else {
      ReNotification({
        description: `Error While ${drawerType === "create" ? "Creating" : "Updating"} Sponsor/MAH`,
        type: "error",
      });
    }
  };

  const onClose = () => {
    setVisibility({
      visibility: false,
      drawerType: "create",
    });
    setCurrEditingSponor({});
  };

  useEffect(() => {
    const configs = JSON.parse(currEditingSponor?.configs || "{}");
    form.setFieldsValue({
      name: currEditingSponor.name,
      email: currEditingSponor.email,
      phoneNumber: currEditingSponor.phoneNumber,
      abbreviation: currEditingSponor.abbreviation,
      timeZone: "IST",
      status: currEditingSponor.status === 1 ? true : false,
      address1: currEditingSponor.address1,
      address2: currEditingSponor.address2,
      city: currEditingSponor.city,
      state: currEditingSponor.state,
      zipCode: currEditingSponor.zipCode,
      country: currEditingSponor.country,
      aiCategorization: currEditingSponor.aiCategorization,
      translate: currEditingSponor.translate,
      wfOneDays: configs?.wfOneDays,
      wfTwoDays: configs?.wfTwoDays,
      qcDays: configs?.qcDays,
      familyName: currEditingSponor?.meta?.familyName,
      givenName: currEditingSponor?.meta?.givenName,
      senderInterchangeId: currEditingSponor?.meta?.senderInterchangeId,
      receiverInterchangeId: currEditingSponor?.meta?.receiverInterchangeId || currEditingSponor?.meta?.senderInterchangeId,
    });
  }, [visibility]);

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  return (
    <Styles.Container>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width={"750"}
        title={
          drawerType === "edit" ? `Edit ${currEditingSponor?.name}'s Data` : "Create New Sponsor"
        }
      >
        <ReusableForm
          formInstance={form}
          onSubmit={onFinish}
          className="form"
          submitBtn
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "0.5rem",
            }}
          >
            <ReInput
              label="Sponsor/MAH Name"
              name="name"
              type="simple"
              required
            />
            <ReInput
              label="Email ID"
              name="email"
              type="simple"
              required
            />
            <ReInput
              label="Sponsor/MAH Unit"
              name="abbreviation"
              type="simple"
              required
            />
            <ReInput
              label="Phone Number"
              name="phoneNumber"
              type="simple"
              required
            />
            <ReInput
              label="Address 1"
              name="address1"
              type="simple"
            />
            <ReInput
              label="Address 2"
              name="address2"
              type="simple"
            />
            <ReInput
              label="City"
              name="city"
              type="simple"
              required
            />
            <ReInput
              label="State"
              name="state"
              type="simple"
              required
            />
            <ReInput
              label="Zip Code"
              name="zipCode"
              type="number"
              onChange={(evt: any) => {
                let value = evt.target.value;
                value = value.replaceAll(/\s/g, "");
                value = value.replace(/\D/g, "");
                form.setFieldValue("zipCode", value);
              }}
              required
            />
            <ReSelect
              label="Country"
              name="country"
              items={allCountries?.map((cou: any) => {
                return {
                  value: cou.name,
                  title: cou.name,
                };
              })}
              searchable
              required
            />
            <ReInput
              label="Given name"
              name="givenName"
              type="simple"
              required
            />
            <ReInput
              label="Family Name"
              name="familyName"
              type="simple"
              required
            />
            <ReInput
              label="Interchange ID"
              name="senderInterchangeId"
              type="simple"
              required
            />
            {/* <ReInput
              label="Interchange ID"
              name="receiverInterchangeId"
              type="simple"
              required
            /> */}
          </div>

          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </Styles.Container>
  );
};
