import { Button, Checkbox, Form, Space, Tag } from "antd";
import { Header, SideMenu } from "../../../Components";
import * as Styles from "./GenerateReportStyles";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllSeriousness } from "../../../slices/ControlledVocabularies/Helpers/getAllSeriousness";
import { GetAllProducts } from "../../../slices/Product/Helpers/GetAllProducts";
import { getAllCountries } from "../../../slices/ControlledVocabularies/Helpers/getAllCountries";
import { importEngine } from "../../../API/ImportEngine";
import moment from "moment";
import ReusableForm from "../../../unity-ui-common/Components/ReForm";
import ReSelect from "../../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReInput from "../../../unity-ui-common/Components/ReFormFields/ReInput";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import ReTab from "../../../unity-ui-common/Components/ReTab";
import ReDrawer from "../../../unity-ui-common/Components/ReDrawer";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import { categorization } from "../../../API/Masters/Categorization/Categorization";
import {
  convertDateInReadableFormat,
  truncate,
} from "../../../Healper/Methods";
import {
  AgeGroupMappingByValue,
  GenderMappingByValue,
  KPIStatusMapping,
  StageMappingByValue,
  StatusEnumToValueDisplay,
} from "../../../Healper/Mappings";
import {
  ISelectedColumnData,
  completionStatusItems,
  selectionCriteriaOptions,
} from "./Constant";
import {
  CommonAgeGroupFieldOptions,
  CommonCategorizationOptions,
  CommonGenderFieldOptions,
  CommonSubCategorizationOptions,
} from "../../../Configs/CommonFormFields";
import { StatusDropDown } from "./Constant";
import { API_URL } from "../../../API/Config";

function GenerateReport() {
  const [fIlterFormInstance] = Form.useForm();
  const dispatch = useAppDispatch();
  const [emailRecordsFormInstance] = Form.useForm();
  const { allProduct } = useAppSelector((store) => store.product);
  const { allSeriousness, allCountries } = useAppSelector(
    (store) => store.controlledVocabularies
  );
  const { valueToTitleMapper } = useAppSelector(
    (store) => store.categorization
  );

  const workflowItems = [
    {
      title: "Workflow 1",
      value: 0
    },
    {
      title: "Workflow 2",
      value: 1
    },
    {
      title: "QC",
      value: 3
    },
  ];

  const selectedColumnsData: ISelectedColumnData[] = [
    {
      dataIndex: "id",
      title: "Record ID",
      columnId: "idColumn",
      width: 170,
    },
    {
      dataIndex: "citation",
      title: "Citation",
      columnId: "citationColumn",
      width: 200,
      render: (citation: string) => {
        const text = citation ? truncate(citation, 80) : "-";
        return text;
      },
    },
    {
      dataIndex: "dateOfSearch",
      title: "Day 0",
      width: 120,
      columnId: "dateOfSearchColumn",
      render: (dateOfSearch: string) => {
        const text =
          dateOfSearch !== "undefined" && dateOfSearch !== null
            ? convertDateInReadableFormat(dateOfSearch)
            : "-";
        return text;
      },
    },
    {
      dataIndex: "productRelatedData",
      title: "Product Name",
      width: 180,
      columnId: "productRelatedDataColumn",
      render: (productRelatedData: any) => {
        const text =
          productRelatedData?.length > 0 ? productRelatedData.join(",") : "-";
        return text;
      },
    },
    {
      dataIndex: "reportData",
      title: "Event Reported Term",
      columnId: "reportDataColumn",
      width: 200,
      render: (value: any) => {
        const text = value ? value.join(",") : "-";
        return text;
      },
    },
    {
      dataIndex: "duplicateOf",
      title: "Duplicate Of",
      columnId: "duplicateOfColumn",
      width: 200,
      render: (value: any) => {
        const text = value ? value.join(",") : "-";
        return text;
      },
    },
    {
      title: "PT",
      dataIndex: "ptData",
      columnId: "ptDataColumn",
      width: 200,
      render: (pt: any) => {
        const text = pt.length !== 0 ? pt.join(", ") : "-";
        return text;
      },
    },
    {
      title: "SOC",
      dataIndex: "socData",
      columnId: "socDataColumn",
      width: 200,
      render: (soc: any) => {
        const text = soc.length !== 0 ? soc.join(", ") : "-";
        return text;
      },
    },

    {
      dataIndex: "seriousnessValue",
      title: "Seriousness",
      width: 250,
      columnId: "seriousnessValueColumn",
      render: (values: string[]) => {
        const text =
          values.length !== 0
            ? values
              .map((ser) => {
                return allSeriousness?.find((se: any) => se.slug === ser)
                  ?.name;
              })
              .join(", ")
            : "-";
        return text;
      },
    },
    {
      title: "Narrative",
      dataIndex: "comment",
      width: 200,
      columnId: "commentColumn",
      render: (comment: any) => {
        const text = comment && comment.length >= 2 ? comment : "No Data";
        return text;
      },
    },
    {
      title: "Comment",
      dataIndex: "comments",
      width: 200,
      columnId: "commentColumn",
      render: (comments: any) => {
        const text = comments && comments.length >= 2 ? comments : "No Data";
        return text;
      },
    },
    // {
    //   title: "AI Categorization",
    //   dataIndex: "aiCategorization",
    //   columnId: "aiCategorizationColumn",
    //   width: 210,
    //   render: (aiCategorization: any) => {
    //     const newData = aiCategorization?.map((ele: number) => {
    //       const data = valueToTitleMapper[ele];
    //       return (
    //         <p key={ele} style={{ color: data?.color }}>
    //           {data?.text}
    //         </p>
    //       );
    //     });
    //     return newData.length ? newData : "-";
    //   },
    // },
    {
      dataIndex: "categorization",
      columnId: "categorizationColumn",
      title: "Record Categorization",
      width: 200,
      render: (categorization: any) => {
        const newData = categorization?.map((ele: number) => {
          const data = valueToTitleMapper[ele];
          return (
            <p key={ele} style={{ color: data?.color }}>
              {data?.text}
            </p>
          );
        });
        return newData.length ? newData : "-";
      },
    },
    {
      dataIndex: "subCategorization",
      columnId: "subCategorizationColumn",
      title: "Sub Categorization",
      width: 170,
      render: (subCate: any) => {
        return subCate ? subCate.toString() : "-";
      },
    },
    {
      dataIndex: "D_5_sex",
      columnId: "D_5_sexColumn",
      title: "Gender",
      width: 120,
      render: (value: number) => {
        return GenderMappingByValue(value);
      },
    },
    {
      dataIndex: "D_2_3_patientAgeGroupAsPerReporter",
      columnId: "D_2_3_patientAgeGroupAsPerReporterColumn",
      title: "Age Group",
      width: 120,
      render: (value: number) => {
        return AgeGroupMappingByValue(value);
      },
    },

    {
      // dataIndex: "C_2_r_primarySourcesOfInformationRepeatAsNecessary",
      dataIndex: "reporterCountry",
      columnId: "reporterCountryColumn",
      title: "Reporter Country",
      width: 200,
      render: (value: any, record: any) => {
        return record?.C_2_r_primarySourcesOfInformationRepeatAsNecessary
          ?.C_2_r_3_reportersCountryCode;
      },
    },
    {
      // dataIndex: "C_2_r_primarySourcesOfInformationRepeatAsNecessary",
      dataIndex: "primaryReporter",
      columnId: "primaryReporterColumn",
      title: "Primary Reporter",
      width: 200,
      render: (value: any, record: any) => {
        const text = record?.C_2_r_primarySourcesOfInformationRepeatAsNecessary
          ?.C_2_r_1_1_reportersTitle
          ? record.C_2_r_primarySourcesOfInformationRepeatAsNecessary
            ?.C_2_r_1_1_reportersTitle
          : "-";
        return text;
      },
    },

    {
      dataIndex: "abstract",
      columnId: "abstractColumn",
      title: "Abstract",
      width: 200,
      render: (abstract: string) => {
        const text = abstract ? truncate(abstract, 80) : "-";
        return text;
      },
    },
    {
      dataIndex: "articleTitle",
      columnId: "articleTitleColumn",
      title: "Article Title",
      width: 200,
      render: (articleTitle: string) => {
        const text = articleTitle ? truncate(articleTitle, 80) : "-";
        return text;
      },
    },
    {
      dataIndex: "externalRefID",
      columnId: "externalRefIDColumn",
      title: "Reference ID",
      width: 120,
      render: (externalRefID: string) => {
        const text = externalRefID ? externalRefID : "-";
        return text;
      },
    },
    {
      dataIndex: "isMLM",
      columnId: "isMLMColumn",
      title: "MLM",
      width: 120,
      render: (isMLM: any) => {
        if(isMLM === null || isMLM === undefined) return "-";
        const text = isMLM ? "Yes" : "No";
        return text;
      },
    },
    {
      columnId: "translationRequiredColumn",
      title: "Translation Request Status",
      dataIndex: "translationRequestStatus",
      width: 220,
      render: (data: any) => {
        if (data) {
          const { color, text } = StatusEnumToValueDisplay(data)
          return <Tag color={color}>{text}</Tag>;
        }
      },
    },
    {
      columnId: "ftpRequiredColumn",
      title: "FTP Request Status",
      dataIndex: "ftpRequestStatus",
      width: 230,
      render: (data: any) => {
        if (data) {
          const { color, text } = StatusEnumToValueDisplay(data)
          return <Tag color={color}>{text}</Tag>;
        }
      },
    },
    // {
    //   dataIndex: "stage",
    //   columnId: "stageColumn",
    //   title: "Workflow",
    //   width: 170,
    //   render: (stage: any) => {
    //     const text = StageMappingByValue(stage).text;
    //     const color = StageMappingByValue(stage).color;
    //     return <Tag color={color}>{text}</Tag>;
    //   },
    // },
    {
      dataIndex: "workflow1DD",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (Due Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"]?.dueDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["0"].dueDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow1CD",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (Completion Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"]?.completionDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["0"].completionDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow1CS",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (Completion Status)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text: "No Data" | "Late" | "On Time" = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"].status >= 0
        ) {
          text = record.completionStatus["0"].status === 1 ? "On Time" : "Late";
        }
        return <Tag color={KPIStatusMapping?.[text]}>{text}</Tag>;
      },
    },

    {
      dataIndex: "workflow2DD",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (Due Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.dueDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["1"].dueDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow2CD",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (Completion Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.completionDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["1"].completionDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow2CS",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (Completion Status)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text: "No Data" | "Late" | "On Time" = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.status >= 0
        ) {
          text = record.completionStatus["1"].status === 1 ? "On Time" : "Late";
        }
        return <Tag color={KPIStatusMapping?.[text]}>{text}</Tag>;
      },
    },

    {
      dataIndex: "qcDD",
      columnId: "completionStatusColumn",
      title: "Quatity Check (Due Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["3"] &&
          record.completionStatus["3"]?.dueDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["3"].dueDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "qcCD",
      columnId: "completionStatusColumn",
      title: "Quality Check (Completion Date)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["3"] &&
          record.completionStatus["3"]?.completionDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["3"].completionDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "qcCS",
      columnId: "completionStatusColumn",
      title: "Quality Check (Completion Status)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text: "No Data" | "Late" | "On Time" = "No Data";
        if (
          record.completionStatus["3"] &&
          record.completionStatus["3"]?.status >= 0
        ) {
          text = record.completionStatus["3"].status === 1 ? "On Time" : "Late";
        }
        return <Tag color={KPIStatusMapping?.[text]}>{text}</Tag>;
      },
    },
  ];

  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [activeTabValue, setActiveTabValue] = useState<string>("1");
  const [submitting, setSubmitting] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [searchedRecordData, setSearchedRecordData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [emailRecordsDrawerVisibility, setEmailRecordsDrawerVisibility] =
    useState<boolean>(false);
  const [isWorkflowFieldHasValue, setIsWorkflowFieldHasValue] =
    useState<boolean>(true);
  const [generatingBtnLoading, setGeneratingBtnLoading] =
    useState<boolean>(false);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(0);

  const onFinish = async (values: any) => {
    values["startDate"] = moment(values?.day0[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    values["endDate"] = moment(values?.day0[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z';
    delete values.day0;

    if (values.dueDate) {
      values.dueDate = {
        startDate:  moment(values?.dueDate[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
        endDate:  moment(values?.dueDate[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z',
      };
    } else {
      delete values.dueDate;
    }

    if (values.stage && values.stage.length === 0) {
      delete values.stage;
    }

    const newSelectedColumns = selectedColumns.filter((ele) => {
      if (ele === "dueDate" || ele === "status") {
        return false;
      } else {
        return true;
      }
    });
    values["columnNames"] = newSelectedColumns;

    if (values.reporter) {
      values.reporter = [values.reporter];
    }
    if (values.productName) {
      values.productName = [values.productName];
    }
    if (values.eventTerm) {
      values.eventTerm = [values.eventTerm];
    }

    if (isGenerate) {
      setGeneratingBtnLoading(true);
      values["seletedKeys"] = selectedRowKeys;
      const res = await importEngine.generateReport(values, "Generate Report");
      if (res.statusCode === 200) {
        ReNotification({
          description: "Your Report is Generating",
          placement: "bottomLeft",
          type: "success",
        });
      } else {
        ReNotification({
          description: "Something Went Wrong",
          placement: "bottomLeft",
          type: "error",
        });
      }
    } else {
      setSubmitting(true);
      const res = await importEngine.searchRecord(values);
      if (res.status) {
        setSearchedRecordData(
          res.data.map((ele: any) => {
            return {
              ...ele,
              comment: ele.comment ? truncate(ele.comment, 50) : "-",
            };
          })
        );
        ReNotification({
          description: `${res.data.length} Records are Generated`,
          placement: "bottomLeft",
          type: "success",
        });
        setPaginationPageSize(res?.data?.length);
      } else {
        ReNotification({
          description: "Something Went Wrong",
          placement: "bottomLeft",
          type: "error",
        });
      }
    }
    setIsGenerate(false);
    setGeneratingBtnLoading(false);
    setSubmitting(false);
  };

  const handleFormValueChanges = (changedValues: any, allValues: any) => {
    if (
      Object.keys(allValues).includes("stage") &&
      allValues.stage.length > 0
    ) {
      setIsWorkflowFieldHasValue(false);
    } else {
      setIsWorkflowFieldHasValue(true);
    }
  };

  const generateColumns = (selectedColumns: any) => {
    const columns: any = [];
    selectedColumnsData.forEach((col) => {
      if (selectedColumns.includes(col.dataIndex)) {
        columns.push({
          title: col.title,
          dataIndex: col.dataIndex,
          width: col.width,
          render: col.render,
        });
      }
    });
    setColumnData(columns);
  };

  const handleSelectAllBtnClicks = (type: "all" | "selected") => {
    const selected = [
      "id",
      // "aiCategorization",
      "categorization",
      "subCategorization",
      "D_5_sex",
      "D_2_3_patientAgeGroupAsPerReporter",
      "reportData",
      "duplicateOf",
      "seriousnessValue",
      "reporterCountry",
      // "originCountry",
      "primaryReporter",
      "citation",
      "abstract",
      "articleTitle",
      "externalRefID",
      "dateOfSearch",
      "translationRequestStatus",
      "ftpRequestStatus",
      "isMLM",
      "ptData",
      "socData",
      "comment",
      "comments",
      // "stage",
      // "dueDate",
      "completionStatus",
      "productRelatedData",
    ];
    if (type === "all") {
      setSelectedColumns([
        ...selected,
        "workflow1DD",
        "workflow1CD",
        "workflow1CS",
        "workflow2DD",
        "workflow2CD",
        "workflow2CS",
        "qcDD",
        "qcCD",
        "qcCS"
      ]);
    } else {
      setSelectedColumns(selected);
    }
  };

  const handleEmailRecordsFormSubmit = async (values: any) => {
    const dontPassTheseFields = [
      "dueDate",
      "status",
      "workflow1DD",
      "workflow1CD",
      "workflow1CS",
      "workflow2DD",
      "workflow2CD",
      "workflow2CS",
      "qcDD",
      "qcCD",
      "qcCS",
    ];
    let newSelectedColumns = selectedColumns.filter((ele, i) => {
      if (dontPassTheseFields.includes(ele)) {
        return false;
      } else {
        return true;
      }
    });
    newSelectedColumns = newSelectedColumns.map((ele) => {
      if (ele === "reporterCountry") {
        return "originCountry";
      }
      if (ele === "primaryReporter") {
        return "C_2_r_primarySourcesOfInformationRepeatAsNecessary"
      }
      return ele;
    })
    const payload = {
      option: {
        selectFields: newSelectedColumns,
        caseIds: selectedRowKeys,
      },
      viewName: "selectedCase",
      emails: values.to.split(","),
      subject: values.subject,
      filter: {},
      text: values.text,
    };

    const res = await importEngine.shareCase(payload);
    if (res.status) {
      ReNotification({
        description: res.message,
        type: "success",
      });
      setSelectedRowKeys([]);
      setEmailRecordsDrawerVisibility(false);
      emailRecordsFormInstance.resetFields();
    } else {
      ReNotification({
        description: res.message,
        type: "error",
      });
    }
  };
  const initialLoadApi = async () => {
      setAllCategory((await categorization.getAll())?.data);
    }
  const onFinishFailed = (errors: any) => {
    errors?.errorFields && errors?.errorFields.forEach((errorData: any) => {
      ReNotification({
        description: errorData?.errors.join(", "),
        type: "error"
      });
    });
  };

  useEffect(() => {
    emailRecordsFormInstance.setFieldsValue({
      subject: "Shared Citations from UNITYai.Lit"
    });
    initialLoadApi();
    handleSelectAllBtnClicks("selected");
    dispatch(getAllSeriousness());
    dispatch(GetAllProducts());
    dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    generateColumns(selectedColumns);
  }, [selectedColumns]);

  return (
    <Styles.Container className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ReusableForm
            formInstance={fIlterFormInstance}
            onSubmit={onFinish}
            onSubmitFailed={onFinishFailed}
            onChange={handleFormValueChanges}
            className="form"
          >
            <ReTab
              activeKey={activeTabValue}
              defaultOpenTab={activeTabValue}
              onChange={(id: string) => setActiveTabValue(id)}
              items={[
                {
                  title: "General",
                  key: "1",
                  children: (
                    <>
                      <div className="categorization">
                      <ReSelect
              label="Record Categorization"
              name="categorization"
              items={allCategory?.length ? allCategory
                ?.map((item) => {
                  return {
                    title: item.name,
                    value: item.numid,
                    disable: item.status === 0
                  };
                }) : []}
              type="multiple"
             
              searchable
            />
                        <ReSelect
                          label="Sub Categorization"
                          name="subCategorization"
                          items={CommonSubCategorizationOptions}
                          type="multiple"
                          searchable
                        />
                        {/* <ReSelect
                          label="AI Categorization"
                          name="aiCategorization"
                          items={CommonCategorizationOptions}
                          type="multiple"
                          searchable
                        /> */}
                        <ReSelect
                          label="FTP Requests"
                          name="ftpRequestStatus"
                          items={StatusDropDown}
                          type="multiple"
                          searchable
                        />
                        <ReSelect
                          label="Translation Requests"
                          name="translationRequestStatus"
                          items={StatusDropDown}
                          type="multiple"
                          searchable
                        />
                        <ReDatePicker
                        label="Day 0"
                        name="day0"
                        dateFormat="DD-MMM-YYYY"
                        featureDates
                        required
                        type="rangePicker"
                        />
                      </div>
                      {/* <ReDatePicker
                        label="Day 0"
                        name="day0"
                        dateFormat="DD-MMM-YYYY"
                        featureDates
                        required
                        type="rangePicker"
                      /> */}
                    </>
                  ),
                },
                {
                  title: "Patient",
                  key: "2",
                  children: (
                    <div className="twoColumn">
                      <ReSelect
                        label="Gender"
                        name="gender"
                        items={CommonGenderFieldOptions}
                        searchable
                      />
                      <ReSelect
                        label="Age Group"
                        name="ageGroup"
                        items={CommonAgeGroupFieldOptions}
                        searchable
                      />
                    </div>
                  ),
                },
                {
                  title: "Event",
                  key: "3",
                  children: (
                    <div className="twoColumn">
                      <ReInput
                        label="Event Reported Term"
                        name="eventTerm"
                        type="simple"
                      />
                      <ReSelect
                        label="Seriousness Criteria"
                        name="seriousness"
                        items={allSeriousness?.map((ser) => {
                          return {
                            title: ser.name,
                            value: ser.slug,
                          };
                        })}
                        type="multiple"
                        searchable
                      />
                    </div>
                  ),
                },
                {
                  title: "Product",
                  key: "4",
                  children: (
                    <div className="twoColumn">
                      <ReSelect
                        label="Product Name"
                        name="productName"
                        items={allProduct?.map((pro) => {
                          return {
                            title: pro.name,
                            value: pro.name,
                          };
                        })}
                        searchable
                      />
                      <ReSelect
                        label="MLM"
                        name="isMLM"
                        items={[
                          {
                            title: "Yes",
                            value: true,
                          },
                          {
                            title: "No",
                            value: false,
                          },
                        ]}
                        searchable
                      />
                    </div>
                  ),
                },
                {
                  title: "Reporter",
                  key: "5",
                  children: (
                    <div className="twoColumn">
                      <ReSelect
                        label="Reporter Country"
                        name="countryOfOrigin"
                        items={allCountries?.map((cou) => {
                          return {
                            title: cou.name,
                            value: cou.name,
                          };
                        })}
                        searchable
                      />
                      <ReInput
                        label="Primary Reporter"
                        name="reporter"
                        type="simple"
                      />
                    </div>
                  ),
                },
                {
                  title: "KPI Criteria",
                  key: "6",
                  children: (
                    <div className="categorization">
                      <ReSelect
                        label="Workflow"
                        name="stage"
                        items={workflowItems.map((wo) => {
                          return {
                            title: wo.title,
                            value: wo.value,
                          };
                        })}
                        type="multiple"
                        searchable
                      />
                      <ReDatePicker
                        label="Due Date"
                        name="dueDate"
                        dateFormat="DD-MMM-YYYY"
                        // featureDates
                        width="100%"
                        type="rangePicker"
                        disable={isWorkflowFieldHasValue}
                      />
                      <ReSelect
                        label="Completion Status"
                        name="status"
                        items={completionStatusItems.map((wo) => {
                          return {
                            title: wo.title,
                            value: wo.value,
                          };
                        })}
                        disable={isWorkflowFieldHasValue}
                        searchable
                      />
                    </div>
                  ),
                },
                {
                  title: "Display Criteria",
                  key: "7",
                  children: (
                    <>
                      <Space direction="horizontal">
                        <Form.Item>
                          <Button
                            onClick={() => {
                              handleSelectAllBtnClicks("all");
                            }}
                            size="small"
                          >
                            Select All
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            onClick={() => {
                              setSelectedColumns([]);
                            }}
                            size="small"
                          >
                            Unselect
                          </Button>
                        </Form.Item>
                      </Space>
                      <Checkbox.Group
                        value={selectedColumns}
                        className="checkBoxes"
                        onChange={(selected) => {
                          setSelectedColumns(selected);
                        }}
                        options={selectionCriteriaOptions}
                      />
                    </>
                  ),
                },
              ]}
              tabBarExtraContent={
                <Form.Item noStyle>
                  <Space direction="horizontal">
                    <Button
                      onClick={() => {
                        fIlterFormInstance.resetFields();
                      }}
                    >
                      Clear Filter
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                    >
                      Search
                    </Button>
                  </Space>
                </Form.Item>
              }
            />
            {searchedRecordData.length > 0 && (
              <ReTable
                showTableStats
                className="resultTable"
                name="results"
                title="Results"
                columns={columnData}
                data={searchedRecordData}
                loading={submitting}
                scroll={{
                  x: 1000,
                  y: 350,
                }}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  type: "checkbox",
                  setSelectedRowKeys: setSelectedRowKeys,
                  rowKey: "id",
                }}
                headerButtons={[
                  {
                    title: "Export to PDF",
                    type: "default",
                    onClick: () => {
                      setIsGenerate(true);
                    },
                    loading: generatingBtnLoading,
                    htmlType: "submit",
                  },
                  {
                    title: "Email Records",
                    type: "primary",
                    onClick: () => {
                      setEmailRecordsDrawerVisibility(true);
                    },
                    disabled: selectedRowKeys.length > 0 ? false : true,
                  },
                ]}
                exportOption={{
                  csv: {
                    columns: selectedColumns,
                    disabled: selectedRowKeys.length === 0,
                    params: {
                      uniView: "import-engine",
                      viewName: "all",
                      apiUrl: API_URL,
                      option: {
                        orderBy: {
                          key: "serialNumber",
                          order: "DESC",
                        },
                      },
                    }
                  },
                }}
                pagination={{
                  total: paginationPageSize,
                }}
              // uniView={{
              //   uniView: "import-engine",
              //   viewName: "all",
              //   apiUrl: API_URL,
              // }}
              />
            )}
          </ReusableForm>
          <ReDrawer
            visibility={emailRecordsDrawerVisibility}
            onCancel={() => {
              setEmailRecordsDrawerVisibility(false);
            }}
            title="Email Records"
            width={600}
          >
            <ReusableForm
              formInstance={emailRecordsFormInstance}
              onSubmit={handleEmailRecordsFormSubmit}
              className="form"
              submitBtn
              submitBtnText="Send"
            >
              <ReInput name="subject" label="Subject" type="simple" required />
              <ReInput name="to" label="To" type="textArea" required />
              <ReInput name="text" label="Body" type="textArea" />
            </ReusableForm>
          </ReDrawer>
        </Styles.SubContainer>
      </div>
    </Styles.Container>
  );
}

export default GenerateReport;
