import { Header, SideMenu } from "../../Components";
import * as Styles from "./ViewUsersStyles";
import { useEffect, useState } from "react";
import { getOwnerFIlterItems, workFlowTypes } from "./Constant";
import { convertDateInReadableFormat, truncate } from "../../Healper/Methods";
import { Button, Form, Space, Tag } from "antd";
import { assignCase } from "../../slices/Record/Helpers/assignCase";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { auth } from "../../API/Auth";
import { Uniview } from "../../API/Uniview";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { booleanColumnFilterItems } from "../Records/AllRecords/Constant";
import { API_URL } from "../../API/Config";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import {
  DuplicateCaseTableDisplay,
  SourceMappingByValue,
  StageMappingByValue,
} from "../../Healper/Mappings";
import { useLocation, useNavigate } from "react-router-dom";

function ViewUsers() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workflowType: any = queryParams.get("workflow");
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { valueToTitleMapper } = useAppSelector(
    (store) => store.categorization
  );

  const navigate = useNavigate();
  const [users, setUsers] = useState<any>([]);
  const [isUserSelected, setIsUserSelected] = useState<boolean>(true);
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [viewName, setViewName] = useState<string>("workflowOneAndUnassigned");
  const [allCategorization, setAllCategorization] = useState<any>([]);
  const [ownerFilterItems, setOwnerFilterItems] = useState<any>([]);
  const [tableRefresh, setTableRefresh] = useState<boolean>(false);

  useEffect(() => {
    getCategorizationData();
    getOwnerFIlterItems();
  }, [])

  useEffect(() => {
    handleInitialLoad(workflowType);
    form.setFieldsValue({
      workflow: workflowType,
      users: ""
    });
    setViewName(workflowType === "workflowOne" ? "workflowOneAndUnassigned" : workflowType);
    setSelectedTableRow([]);
    setTableRefresh(prevValue => !prevValue);
  }, [workflowType]);

  const commonColumns = [
    {
      title: "Record ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Workflow",
      dataIndex: "stage",
      key: "stage",
      render: (stage: any, record: any) => {
        return <Tag color={StageMappingByValue(record.stage).color}>{StageMappingByValue(record.stage).text}</Tag>;
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (source: any) => {
        const text = SourceMappingByValue(source);
        return text;
      },
    },
    {
      title: "Day 0",
      dataIndex: "dateOfSearch",
      key: "dateOfSearch",
      render: (dateOfSearch: any) => {
        return convertDateInReadableFormat(dateOfSearch);
      }
    },
    {
      title: "Owner",
      dataIndex: "userName",
      key: "userName",
      render: (userName: string) => {
        const text = userName ? userName : "Not Assigned";
        return text;
      },
    },
    {
      title: "Record Title",
      dataIndex: "sourceArticleTitle",
      width: 250,
      key: "sourceArticleTitle",
      render: (record: any) => {
        return <p>{record ? truncate(record, 80) : "-"}</p>;
      },
    },
    // {
    //   title: "AI Categorization",
    //   dataIndex: "aiCategorization",
    //   key: "aiCategorization",
    //   render: (aiCategorization: any) => {
    //     const newData = aiCategorization.map((ele: number) => {
    //       const data = valueToTitleMapper[ele];
    //       return <p style={{ color: data?.color }}>{data?.text}</p>;
    //     });
    //     return newData.length ? newData : "-";
    //   },
    // },
    {
      title: "Record Categorization",
      dataIndex: "categorization",
      key: "categorization",
      render: (categorization: any) => {
        const newData = categorization.map((ele: number) => {
          const data = valueToTitleMapper[ele];
          return <p style={{ color: data?.color }}>{data?.text}</p>;
        });
        return newData.length ? newData : "-";
      },
    },
    {
      title: "Auto Translated",
      dataIndex: "caseTranslated",
      key: "caseTranslated",
      render: (record: any) => {
        const text = record === true ? "Yes" : "No";
        const color = record === true ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
    // {
    //   title: "AI Processed",
    //   dataIndex: "caseAiCategorized",
    //   key: "caseAiCategorized",
    //   render: (record: any) => {
    //     const text = record === true ? "Yes" : "No";
    //     const color = record === true ? "green" : "red";
    //     return (
    //       <Tag color={color} title="status">
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Duplicate",
      dataIndex: "duplicate",
      key: "duplicate",
      render: (duplicate: string, record: any) => {
        return (
          <Tag color={DuplicateCaseTableDisplay(record.duplicate).color}>
            {DuplicateCaseTableDisplay(duplicate).text}
          </Tag>
        );
      },
    },
  ];

  const handleInitialLoad = async (workflow: string) => {
    setUsers([]);
    let userList = await getUsers(workflow)
    setUsers(userList);
  };

  const getUsers = async (workflow: any) => {
    const uniview = new Uniview("user", workflow);
    const res = await uniview.fetchData(
      {},
      { orderBy: { name: "ASC" } }
    );
    return res.data;
  };

  const handleFilterSubmit = async (values: any) => {
    if (!isUserSelected) {
      const payload = {
        casesId: selectedTableRow,
        userId: values.users,
        type: values.workflow,
      };
      dispatch(assignCase(payload));
    }
    setIsUserSelected(true);
  };

  const handleFilterChanges = async (value: any, allValues: any) => {
    const key = Object.keys(value);
    const keyValue = value[key[0]];

    if (key.includes("workflow")) {
      navigate(`/imports/manual-work-allocation?workflow=${keyValue}`)
    }
    if (key.includes("users")) {
      setIsUserSelected(false);
    } else {
      setIsUserSelected(true);
    }
  };

  const getCategorizationData = async () => {
    setOwnerFilterItems(await getOwnerFIlterItems())
    const univiewService = new Uniview("masterData", "categorization");
    const res = await univiewService.fetchData({}, {});
    if (res.status) {
      setAllCategorization(
        res.data.map((ele: any) => {
          return {
            text: ele.name,
            value: ele.numid,
          };
        })
      );
    }
  };

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <Styles.SubContainer className="subContainer">
          <ReusableForm
            formInstance={form}
            onSubmit={handleFilterSubmit}
            onChange={handleFilterChanges}
            formStyles={{ marginBottom: "0.5rem" }}
          >
            <div className="formIitems">
              <ReSelect
                searchable
                label="Workflow"
                name="workflow"
                items={workFlowTypes}
              />
              <ReSelect
                label="Users"
                name="users"
                items={users.map((use: any) => {
                  return {
                    title: use.name,
                    value: use.id,
                  };
                })}
                searchable
                disable={users?.length === 0}
              />
              <Form.Item noStyle>
                <Space style={{ width: "7rem", marginTop: "5px" }}>
                  <Button
                    disabled={isUserSelected || selectedTableRow.length < 1}
                    type="primary"
                    htmlType="submit"
                  >
                    Assign
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </ReusableForm>
          <Styles.Tables>
            <ReTable
              showTableStats
              name={viewName}
              className="workflowChart"
              refresh={tableRefresh}
              uniView={{
                uniView: "import-engine",
                viewName: viewName || "workflowOneAndUnassigned",
                apiUrl: API_URL,
                option: {
                  orderBy: {
                    key: "serialNumber",
                    order: "DESC",
                  },
                  limit: 10
                },
              }}
              columns={commonColumns}
              columnOptions={{
                sorting: {
                  columns: [
                    "id",
                    "source",
                    "stage",
                    "duplicateTitle",
                    "dateOfSearch",
                    "caseTranslated",
                    "caseAiCategorized",
                    "duplicate",
                    "categorization",
                    "aiCategorization",
                    "userName",
                  ],
                },
                filter: {
                  filterSearch: true,
                  columns: [
                    "id",
                    {
                      key: "userName",
                      items: ownerFilterItems,
                    },
                    {
                      key: "source",
                      action: (value: any) => {
                        return SourceMappingByValue(value);
                      },
                    },
                    {
                      key: "stage",
                      action: (value: any) => {
                        return StageMappingByValue(value).text;
                      },
                    },
                    {
                      key: "dateOfSearch",
                      action: (value: any) => {
                        return convertDateInReadableFormat(value);
                      },
                    },
                    {
                      key: "duplicate",
                      action: (value: any) => {
                        return DuplicateCaseTableDisplay(value).text;
                      },
                    },
                    {
                      key: "caseTranslated",
                      items: booleanColumnFilterItems,
                    },
                    {
                      key: "caseAiCategorized",
                      items: booleanColumnFilterItems,
                    },
                    {
                      key: "categorization",
                      items: allCategorization,
                    },
                    {
                      key: "aiCategorization",
                      items: allCategorization,
                    },
                  ],
                },
              }}
              scroll={{
                x: 2500,
                y: 320,
              }}
              rowSelection={{
                selectedRowKeys: selectedTableRow,
                type: "checkbox",
                setSelectedRowKeys: setSelectedTableRow,
                rowKey: "id",
              }}
            />
          </Styles.Tables>
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default ViewUsers;
