import { useState, useEffect } from "react";
import * as Styles from "./AllRecordsStyles";
import Header from "../../../Components/Header/Header";
import SideMenu from "../../../Components/SideMenu/SideMenu";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { useLocation } from "react-router-dom";
import { importEngine } from "../../../API/ImportEngine";
import { Button, Popover, Space, Tag } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { ReNotification } from "../../../unity-ui-common/Components/ReNotification";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addCurrentAccessingCase } from "../../../slices/Record/RecordSlices";
import { CasesDrawer } from "../../../Components/Records/CasesDrawer/CasesDrawer";
import { booleanColumnFilterItems } from "./Constant";
import {
  convertDateInReadableFormat,
  truncate,
} from "../../../Healper/Methods";
import { Uniview } from "../../../API/Uniview";
import { API_URL } from "../../../API/Config";
import {
  DuplicateCaseTableDisplay,
  KPIStatusMapping,
  SourceMappingByValue,
  StageMappingByValue,
} from "../../../Healper/Mappings";
import { getOwnerFIlterItems } from "../../ViewUsers/Constant";
import ReModal from "../../../unity-ui-common/Components/ReModal";
import ExportXMLErrorTable from "./ExportXMLErrorTable";

function AllRecords() {

  const routeMapping: any = {
    "/imports/all-records": ["All Records", "all"],
    "/imports/my-work": ["My Work", "currentUserAssigned"],
    "/imports/unassigned-records": ["Un-Assigned Records", "workflowTwo"]
  };

  const currentuser = JSON.parse(sessionStorage.getItem("user") || '');

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { valueToTitleMapper } = useAppSelector(
    (store) => store.categorization
  );

  const [xmlErrorModalOptions, setXmlErrorModalOptions] = useState({
    children: <>No Error In XML</>,
    title: "XML Export Error",
    visibility: false,
  });
  const [allCasesDrawer, setAllCasesDrawer] = useState({
    visibility: false,
    type: "",
  });
  const [allCategorization, setAllCategorization] = useState<any>([]);
  const [ownerFilterItems, setOwnerFilterItems] = useState<any>([]);
  const [tableRefresh, setTableRefresh] = useState<boolean>(false);

  async function handleExportCase(caseId: string, type: string) {
    if (type == "xml") {
      const res = await importEngine.export(caseId);
      if (res.status) {
        ReNotification({
          description: `${caseId} Export To XML Format Successful.`,
          placement: "bottomLeft",
          type: "info",
        });
      } else {
        setXmlErrorModalOptions({
          ...xmlErrorModalOptions,
          title: `XML Export Error in ${caseId}`,
          children: <ExportXMLErrorTable errorObj={res} />,
          visibility: true
        })
      }
    }
    if (type == "medwatch" || type == "ciomes") {
      const res = await importEngine.generateMedwatchCiomes(caseId, type);
      if (res.statusCode === 200) {
        ReNotification({
          description: "Your file is downloading",
          placement: "bottomLeft",
          type: "info",
        });
      } else {
        ReNotification({
          description: "Something went wrong",
          placement: "bottomLeft",
          type: "error",
        });
      }
    }
  }

  const handleViewCaseDetailClicks = async (id: string) => {
    const res = await importEngine.getCaseData(id);
    dispatch(addCurrentAccessingCase(res[0]));
    setAllCasesDrawer({
      visibility: true,
      type: "edit",
    });
  };

  const commonColumns = [
    {
      width: ["5", "16", "17"].some((permission: any) => currentuser.permissions.includes(permission)) ? 40 : 0,
      dataIndex: "",
      key: "",
      fixed: "left",
      render: (caseId: any, record: any) => {
        const { id } = record;
        return (
          <>
            {["5", "16", "17"].some((permission: any) => currentuser.permissions.includes(permission)) &&
              <Popover
                content={
                  <Space direction="vertical">
                    {currentuser.permissions.includes("5") &&
                      <Button
                        onClick={() => {
                          handleExportCase(id, "xml");
                        }}
                        type="link"
                      >
                        Export as XML
                      </Button>
                    }
                    {/* {currentuser.permissions.includes("16") &&
                    <Button
                      onClick={() => {
                        handleExportCase(id, "medwatch");
                      }}
                      type="link"
                    >
                      Export as MedWatch
                    </Button>
                  } */}
                    {/* {currentuser.permissions.includes("17") &&
                    <Button
                      onClick={() => {
                        handleExportCase(id, "ciomes");
                      }}
                      type="link"
                    >
                      Export as CIOMS
                    </Button>
                  } */}
                  </Space>
                }
              >
                <ExportOutlined style={{ cursor: "pointer" }} />
              </Popover>
            }
          </>
        );
      },
    },
    {
      title: "Record ID",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      render: (id: string, record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              handleViewCaseDetailClicks(id);
            }}
          >
            {id}
          </Button>
        );
      },
    },
    {
      title: "Workflow",
      dataIndex: "stage",
      key: "stage",
      render: (stage: any, record: any) => {
        return <Tag color={StageMappingByValue(record.stage).color}>{StageMappingByValue(record.stage).text}</Tag>;
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (source: any) => {
        const text = SourceMappingByValue(source);
        return text;
      },
    },
    {
      title: "Day 0",
      dataIndex: "dateOfSearch",
      key: "dateOfSearch",
      render: (dateOfSearch: any) => {
        return convertDateInReadableFormat(dateOfSearch);
      }
    },
    {
      title: "Owner",
      dataIndex: "userName",
      key: "userName",
      render: (userName: string) => {
        const text = userName ? userName : "Not Assigned";
        return text;
      },
    },
    {
      title: "Record Title",
      dataIndex: "sourceArticleTitle",
      width: 250,
      key: "sourceArticleTitle",
      render: (record: any) => {
        return <p>{record ? truncate(record, 80) : "-"}</p>;
      },
    },
    // {
    //   title: "AI Categorization",
    //   dataIndex: "aiCategorization",
    //   key: "aiCategorization",
    //   render: (aiCategorization: any) => {
    //     const newData = aiCategorization.map((ele: number) => {
    //       const data = valueToTitleMapper[ele];
    //       return <p style={{ color: data?.color }}>{data?.text}</p>;
    //     });
    //     return newData.length ? newData : "-";
    //   },
    // },
    {
      title: "Record Categorization",
      dataIndex: "categorization",
      key: "categorization",
      render: (categorization: any) => {
        const newData = categorization.map((ele: number) => {
          const data = valueToTitleMapper[ele];
          return <p style={{ color: data?.color }}>{data?.text}</p>;
        });
        return newData.length ? newData : "-";
      },
    },
    {
      title: "Auto Translated",
      dataIndex: "caseTranslated",
      key: "caseTranslated",
      render: (record: any) => {
        const text = record === true ? "Yes" : "No";
        const color = record === true ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
    // {
    //   title: "AI Processed",
    //   dataIndex: "caseAiCategorized",
    //   key: "caseAiCategorized",
    //   render: (record: any) => {
    //     const text = record === true ? "Yes" : "No";
    //     const color = record === true ? "green" : "red";
    //     return (
    //       <Tag color={color} title="status">
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Duplicate",
      dataIndex: "duplicate",
      key: "duplicate",
      render: (duplicate: string, record: any) => {
        return (
          <Tag color={DuplicateCaseTableDisplay(record.duplicate).color}>
            {DuplicateCaseTableDisplay(duplicate).text}
          </Tag>
        );
      },
    },
    {
      dataIndex: "workflow1DD",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (DD)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"]?.dueDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["0"].dueDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow1CD",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (CD)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"]?.completionDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["0"].completionDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow1CS",
      columnId: "completionStatusColumn",
      title: "Workflow 1 (CS)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text: "No Data" | "Late" | "On Time" = "No Data";
        if (
          record.completionStatus["0"] &&
          record.completionStatus["0"].status >= 0
        ) {
          text = record.completionStatus["0"].status === 1 ? "On Time" : "Late";
        }
        return <Tag color={KPIStatusMapping?.[text]}>{text}</Tag>;
      },
    },

    {
      dataIndex: "workflow2DD",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (DD)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.dueDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["1"].dueDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow2CD",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (CD)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.completionDate
        ) {
          text = convertDateInReadableFormat(
            record.completionStatus["1"].completionDate
          );
        }
        return text;
      },
    },
    {
      dataIndex: "workflow2CS",
      columnId: "completionStatusColumn",
      title: "Workflow 2 (CS)",
      width: 170,
      render: (completionStatus: any, record: any) => {
        let text: "No Data" | "Late" | "On Time" = "No Data";
        if (
          record.completionStatus["1"] &&
          record.completionStatus["1"]?.status >= 0
        ) {
          text = record.completionStatus["1"].status === 1 ? "On Time" : "Late";
        }
        return <Tag color={KPIStatusMapping?.[text]}>{text}</Tag>;
      }
    }
  ];

  const getCategorizationData = async () => {
    setOwnerFilterItems(await getOwnerFIlterItems())
    const selectedWorkSpace = JSON.parse(sessionStorage.getItem('selectedWorkSpace') || "{}");
    const univiewService = new Uniview("masterData", "categorization");
    const res = await univiewService.fetchData({ tenantId: { "=": [null, selectedWorkSpace.id] } }, {});
    if (res.status) {
      setAllCategorization(
        res.data.map((ele: any) => {
          return {
            text: ele.name,
            value: ele.numid,
          };
        })
      );
    }
  };

  // const getOwnerFIlterItems = async () => {
  //   let option: any = {
  //     orderBy: {},
  //     selectFields: "userName",
  //     groupBy: "userName",
  //     filters: true,
  //   };

  //   const dataFetcher = new Uniview("import-engine", "getAll");
  //   const res = await dataFetcher.fetchData({}, option);
  //   if (res.status) {
  //     setOwnerFilterItems(
  //       res.data.map((fil: any) => {
  //         return {
  //           value: fil["userName"],
  //           text: fil["userName"] === null ? "Not Assigned" : fil["userName"],
  //         };
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    setTableRefresh(prevValue => !prevValue);
  }, [pathname]);

  useEffect(() => {
    getCategorizationData();
  }, []);

  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <ReModal
          children={xmlErrorModalOptions?.children}
          onCancel={() => {
            setXmlErrorModalOptions({
              ...xmlErrorModalOptions,
              children: <></>,
              visibility: false
            })
          }}
          onOkay={() => { }}
          title={xmlErrorModalOptions?.title}
          visibility={xmlErrorModalOptions?.visibility}
          footer={false}
          width={970}
        />
        <Styles.SubContainer className="subContainer">
          <CasesDrawer
            type={allCasesDrawer.type}
            visibility={allCasesDrawer.visibility}
            setVisibility={setAllCasesDrawer}
            width="1400"
          />
          <ReTable
            name={routeMapping?.[pathname][0]}
            title={routeMapping?.[pathname][0]}
            columns={commonColumns}
            refresh={tableRefresh}
            showTableStats
            uniView={{
              uniView: "import-engine",
              viewName: routeMapping?.[pathname][1] || "",
              apiUrl: API_URL,
              option: {
                orderBy: {
                  key: "serialNumber",
                  order: "DESC",
                },
                limit: 10
              },
            }}
            columnOptions={{
              sorting: {
                columns: [
                  "id",
                  "userName",
                  "source",
                  "stage",
                  "duplicateTitle",
                  "dateOfSearch",
                  "caseTranslated",
                  "caseAiCategorized",
                  "duplicate",
                  "categorization",
                  "aiCategorization",
                ],
                sortingDirections: ["ascend", "descend"],
              },
              filter: {
                filterSearch: true,
                columns: [
                  "id",
                  {
                    key: "userName",
                    action: (value: any) => {
                      return !value ? "Not Assigned" : value;
                    },
                  },
                  {
                    key: "source",
                    action: (value: any) => {
                      return SourceMappingByValue(value);
                    },
                  },
                  {
                    key: "stage",
                    action: (value: any) => {
                      return StageMappingByValue(value).text;
                    },
                  },
                  {
                    key: "dateOfSearch",
                    action: (value: any) => {
                      return convertDateInReadableFormat(value);
                    },
                  },
                  {
                    key: "duplicate",
                    action: (value: any) => {
                      return DuplicateCaseTableDisplay(value).text;
                    },
                  },
                  {
                    key: "caseTranslated",
                    items: booleanColumnFilterItems,
                  },
                  {
                    key: "caseAiCategorized",
                    items: booleanColumnFilterItems,
                  },
                  {
                    key: "categorization",
                    items: allCategorization,
                  },
                  {
                    key: "aiCategorization",
                    items: allCategorization,
                  },
                ],
              },
            }}
            scroll={{
              x: 3500,
            }}
          />
        </Styles.SubContainer>
      </div>
    </div>
  );
}

export default AllRecords;