import { useState, useEffect } from "react";
import { ConfigProvider, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { role } from "../../API/UserManagement/Role";
import * as Styles from "./WorkspaceStyles";
import LogoWithText from "../../Assets/logo.svg";
import { useAppDispatch } from "../../app/hooks";
import { addUserPermissions } from "../../slices/User/UserSlices";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { categorization } from "../../API/Masters/Categorization/Categorization";
import { addTitleToValueMapper, addValueToTitleMapper } from "../../slices/Categorization/CategoizationSlices";
import { getRandomDarkColor, removeDuplicatesFromArray } from "../../Healper/Methods";
import { handleUserLogin } from "../../slices/Session/Session";

function Workspace() {
  const [workspaces, setWorkspaces] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    JSON.parse(
      sessionStorage.getItem("selectedWorkSpace") || '{"name": "", "id": ""}'
    )
  );
  const [roleData, setRoleData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
    const uniqueWorkspaces = Object.values(
      userData.user.tenants.reduce((acc: any, item: any) => {
        acc[item.id] = item;
        return acc;
      }, {})
    );
    setWorkspaces(uniqueWorkspaces);
    setRoleData(userData.user.roleMatrices);
  }, []);

  const createCategorizationMapper = async () => {
    const res = await categorization.getAll();
    if (res.status) {
      let enumToTitleMapper: any = {};
      let titleToEnumMapper: any = {};
      res.data.forEach((item: any) => {
        enumToTitleMapper[item.numid] = {
          text: item.name,
          color: getRandomDarkColor()
        };
        titleToEnumMapper[item.name] = item.numid
      });
      dispatch(addValueToTitleMapper(enumToTitleMapper));
      dispatch(addTitleToValueMapper(titleToEnumMapper))
    }
  };

  async function handleWorkspaceChange(id: any) {
    const workSpace = workspaces.filter((data: any) => data.id === id);
    setSelectedWorkspace(workSpace[0]);
    let tenantRole = JSON.parse(sessionStorage.getItem("tenantRole") || "{}");
    let switchWorkspace = false;

    if (tenantRole && tenantRole.roles) {
      switchWorkspace = true;
      await role.getRoleAndPermission("NA", switchWorkspace, workSpace[0].id);
    }
    let rolesData: any =
      roleData.find((x: { tenant: any }) => x.tenant == id) || [];
    rolesData.permission = removeDuplicatesFromArray(rolesData.permission);
    sessionStorage.setItem("tenantRole", JSON.stringify(rolesData));
    await role.getRoleAndPermission(rolesData.roles.join(','), false, workSpace[0].id);
    if (rolesData && rolesData.roles && rolesData.roles.length) {
      const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
      const roleMatrice = userData.user.roleMatrices.find((role: any) => {
        return role.tenant === id && role.permission;
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...userData, permissions: roleMatrice.permission })
      );
      dispatch(addUserPermissions(roleMatrice.permission));

      // creating cate mapper to user for mapping
      createCategorizationMapper();

      // adding all session storage to redux
      dispatch(handleUserLogin({
        selectedTenantId: workSpace[0].id,
        selectedTenantName: workSpace[0].name,
        selectedTenantRoles: rolesData.roles,
        userPermissions: rolesData.permission
      }));


      navigate("/");
    } else {
      ReNotification({
        description: "There is no role attached to this user",
        type: "error",
      });
    }
    sessionStorage.setItem("selectedWorkSpace", JSON.stringify(workSpace[0]));
     window.location.reload()
  }

  return (
    <Styles.SubContainer>
      <ConfigProvider componentSize="middle">
        <Styles.WorkSpaceContainer>
          <Styles.ImgContainer>
            <img src={LogoWithText} alt="logo with text" className="logo-img" />
          </Styles.ImgContainer>

          <Styles.SelectContainer>
            <ReusableForm
              formInstance={form}
              onChange={(values: any) => {
                handleWorkspaceChange(values.selectedWorkspace);
              }}
              onSubmit={() => { }}
            >
              <ReSelect
                label="Select Workspace"
                name="selectedWorkspace"
                items={workspaces?.map((workspace: any) => {
                  return {
                    value: workspace.id,
                    title: workspace.name,
                  };
                })}
              />
            </ReusableForm>
          </Styles.SelectContainer>
        </Styles.WorkSpaceContainer>
      </ConfigProvider>
    </Styles.SubContainer>
  );
}

export default Workspace;
