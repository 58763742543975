import { useState } from "react";
import SideMenu from "../../Components/SideMenu/SideMenu";
import * as Styles from "./SearchAndGenerateReportStyles";
import { Button, Checkbox, Form } from "antd";
import moment from "moment";
import { importEngine } from "../../API/ImportEngine";
import { convertDateInReadableFormat, truncate } from "../../Healper/Methods";
import { Link } from "react-router-dom";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { Header } from "../../Components";
import {
  SearchOutlined
} from "@ant-design/icons";
import { API_URL } from "../../API/Config";

function SearchAndGenerateReport() {
  const [form] = Form.useForm();
  const [numberOfSearchPerformed, setNumberOfSearchPerformed] = useState<number>(0);
  const [selectedColumns, setSelectedColumns] = useState<any[]>([
    "citation",
    "abstract",
    "articleTitle",
    "createdAt",
    "externalRefID",
    "id",
    "authors",
    "dateOfSearch",
  ]);
  const [tableRefresh, setTableRefresh] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const temp: any = {};
    Object.entries(values).forEach(([key, value]: any) => {
      if (!value && value !== 0) return;
      if (typeof value === "string") {
        temp[key] = {
          '//': value
        }
      }
      else {
        temp[key] = {
          ">": moment(value?.[0]).format('YYYY-MM-DD') + 'T00:00:00.000Z',
          "<": moment(value?.[1]).format('YYYY-MM-DD') + 'T23:59:59.999Z'
        }
      }
    });
    setFilter(temp);
    setTableRefresh(prev=>!prev);
    setNumberOfSearchPerformed(prevValue=>prevValue+1);
  };
  
  const generateColumns = () => {
    const columns = [];

    if (selectedColumns.includes("id")) {
      columns.push({
        title: "Record ID",
        dataIndex: "id",
        render: (id: string) => {
          return (
            <Link to={`/view-record/${id}`} target="_blank">
              {id}
            </Link>
          );
        },
        width: 140,
      });
    }

    if (selectedColumns.includes("citation")) {
      columns.push({
        title: "Citation",
        dataIndex: "citation",
        render: (citation: string) => {
          const text = citation ? truncate(citation, 80) : "-";
          return text;
        },
        width: 300,
      });
    }

    if (selectedColumns.includes("abstract")) {
      columns.push({
        dataIndex: "abstract",
        title: "Abstract",
        width: 300,
        render: (abstract: string) => {
          const text = abstract ? truncate(abstract, 80) : "-";
          return text;
        },
      });
    }

    if (selectedColumns.includes("articleTitle")) {
      columns.push({
        title: "Article Title",
        dataIndex: "articleTitle",
        width: 200,
        render: (articleTitle: string) => {
          const text = articleTitle ? truncate(articleTitle, 80) : "-";
          return text;
        },
      });
    }

    
    if (selectedColumns.includes("createdAt")) {
      columns.push({
        title: "Created Date",
        dataIndex: "createdAt",
        render: (date: any) => {
          return convertDateInReadableFormat(date);
        },
        width: 100
      });
    }

    if (selectedColumns.includes("externalRefID")) {
      columns.push({
        title: "Reference ID",
        dataIndex: "externalRefID",
        render: (externalRefID: string) => {
          const text = externalRefID ? externalRefID : "-";
          return text;
        },
        width: 100
      });
    }

    
    if (selectedColumns.includes("dateOfSearch")) {
      columns.push({
        dataIndex: "dateOfSearch",
        title: "Day 0",
        width: 120,
        render: (dateOfSearch: string) => {
          const text =
            dateOfSearch !== "undefined" && dateOfSearch !== null
              ? convertDateInReadableFormat(dateOfSearch)
              : "-";
          return text;
        },
      });
    }

    if (selectedColumns.includes("authors")) {
      columns.push({
        title: "Authors",
        dataIndex: "authors",
        render: (authors: any) => {
          const text = authors
            ? authors
            : "-";
          return text;
        },
      });
    }

    return columns;
  };

  const selectColumnOptions = [
    { label: "Citation", value: "citation" },
    { label: "Abstract", value: "abstract" },
    { label: "Article Title", value: "articleTitle" },
    { label: "Created Date", value: "createdAt" },
    { label: "Reference ID", value: "externalRefID" },
    { label: "Record ID", value: "id" },
    { label: "Authors", value: "authors" },
    { label: "Day 0", value: "dateOfSearch" },
  ];

  return (
    <Styles.Container>
      <div className="container">
        <Header />
        <div className="page-wrapper">
          <SideMenu />
          <Styles.SubContainer className="subContainer">
            <div style={{ background: 'white' }}>
              <div className="header">
                <h2>Search</h2>
                <Button type="primary" loading={searchLoading} onClick={() => form.submit()}><SearchOutlined />Search</Button>
              </div>
              <ReusableForm
                formInstance={form}
                onSubmit={onFinish}
                className="form"
              >
                <div className="textAreasFields">
                  <ReInput label="Citation" name="citation" type="textArea" />
                  <ReInput label="Article Title" name="articleTitle" type="textArea" />
                  <ReInput label="Abstract" name="abstract" type="textArea" />
                </div>
                <div className="fields">
                  <ReDatePicker
                    label="Created Date"
                    name="createdAt"
                    dateFormat="DD-MMM-YYYY"
                    featureDates
                    type="rangePicker"
                    width="100%"
                  />
                  <ReInput
                    label="Reference ID"
                    name="externalRefID"
                    type="simple"
                    // allowClear
                  />
                  <ReInput 
                    label="Record ID" 
                    name="id" 
                    type="simple" 
                    // allowClear 
                  />
                  <ReDatePicker
                    label="Day 0"
                    name="dateOfSearch"
                    dateFormat="DD-MMM-YYYY"
                    featureDates
                    type="rangePicker"
                    width="100%"
                  />
                  <ReInput label="Authors" name="authors" type="simple" />
                </div>
                <div className="selectColumns">
                  <h3>Select the data elements for this report</h3>
                  <Checkbox.Group
                    className="checkBoxes"
                    options={selectColumnOptions}
                    value={selectedColumns}
                    onChange={(selected) => {
                      setSelectedColumns(selected);
                    }}
                  />
                </div>
              </ReusableForm>
            </div>
            {(selectedColumns.length !== 0 && numberOfSearchPerformed > 0) && (
              <ReTable
                showTableStats
                loading={false}
                name="results"
                title="Search Results"
                columns={generateColumns()}
                refresh={tableRefresh}
                uniView={{
                  uniView: "import-engine",
                  viewName: "all",
                  apiUrl: API_URL,
                  option: {
                    orderBy: {
                      key: "serialNumber",
                      order: "DESC",
                    },
                    limit: 10
                  },
                  filter: filter,
                }}
                scroll={{
                  x: 1200,
                }}
              />
            )}
          </Styles.SubContainer>
        </div>
      </div>
    </Styles.Container>
  );
}

export default SearchAndGenerateReport;
